/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// reducers

// UI Components
import { Form, Button, Layout, notification, Row } from 'antd';
import FormBuilder from 'antd-form-builder';
import { $login, selectSessionLoading } from '../../reducers/Session.slice';

// Local Images
import LOGO from '../../assets/atgmo-logo.png';
import ORANGE from '../../assets/odc-x1.png'

// Style config
import './index.css';

/* -------------------------------------------------------------------------- */
/*                                 Login Page                                 */
/* -------------------------------------------------------------------------- */
function LoginPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSessionLoading);
  const history = useHistory();

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  })

  /* ---------------------------------- CONST --------------------------------- */
  const loginFormFields = {
    fields: [
      {
        key: 'email',
        placeholder: 'Adresse e-mail',
        rules: [
          {
            type: 'email',
            message: "L'entrée n'est pas valide E-mail!",
          },
          {
            required: true,
            message: 'Veuillez saisir votre e-mail !',
          },
        ],
      },
      {
        key: 'password',
        placeholder: 'Mot de passe',
        widget: 'password',
        rules: [
          {
            required: true,
            message: 'Veuillez saisir votre mot de passe !',
          },
        ],
      },
    ],
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const onSubmit = (values) => {
    dispatch($login(values))
      .then(unwrapResult)
      .then(() => {
        notification.success({
          message: 'Utilisateur',
          description: "L'utilisateur s'est connecté avec succès",
        });
        history.push('/home');
      })
      .catch((error) =>
        notification.error({
          message: 'Utilisateur',
          description: error.message,
        }),
      );
  };

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])


  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Layout className="login-page">
      <Layout.Content>
        <Row justify={windowDimenion.winWidth > '800' ? 'start' : 'center'}>
          Le bouton connexion ne fonctionne pas ?
        </Row>
        <Row justify={windowDimenion.winWidth > '800' ? 'start' : 'center'}>
          <Button
            type="primary"
            className="login-button width"
            onClick={() => {
              window.localStorage.clear();
              window.location.reload();
            }}
          >
            Rafraîchir la page
          </Button>
        </Row>
        <Row align="center" justify="center">
          <img src={LOGO} className="atgmo-logo" alt="AtGmo" />
        </Row>
        <Row align="center" justify="center">
          <Form
            name="login-form"
            onFinish={onSubmit}
            form={form}
            initialValues={{ remember: true }}
            className="login-page-layout"
          >
            <p className="form-title">Bienvenue</p>
            <p className="form-description">Connectez-vous au tableau de bord</p>
            <FormBuilder form={form} meta={loginFormFields} className="mb-0" />

            <Form.Item>
              <Button htmlType="submit" type="primary" className="login-button" loading={isLoading}>
                CONNEXION
              </Button>
              <Row className="mt" justify="end">
                <Link to="/forgot-password">Mot de passe oublié ?</Link>
              </Row>
            </Form.Item>
          </Form>
        </Row>
        <Row justify={windowDimenion.winWidth > '800' ? 'start' : 'center'}>
          <img src={ORANGE} className="atgmo-logo" alt="AtGmo" />
        </Row>
      </Layout.Content>
    </Layout>
  );
}

LoginPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default LoginPage;

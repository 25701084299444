/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI Components
import { Tabs, Typography } from 'antd';

// Local Components
import AssociationComponent from '../../components/AssociationComponent';
import QuestionAndAnswerComponent from '../../components/QuestionAndAnswerComponent';
import EventComponent from '../../components/EventComponent';
import DiseaseComponent from '../../components/DiseaseComponent';
import PsychologistComponent from '../../components/PsychologistComponent';

/* -------------------------------------------------------------------------- */
/*                                    ATGMO                                   */
/* -------------------------------------------------------------------------- */
function Atgmo() {
  /* ---------------------------------- CONST --------------------------------- */
  const { TabPane } = Tabs;
  const { Title } = Typography;

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
        <TabPane tab="À propos" key="1">
          <Title level={4}>
            <AssociationComponent />
          </Title>
        </TabPane>
        <TabPane tab="La greffe de moelle osseuse" key="2">
          <Title level={4}>
            <QuestionAndAnswerComponent />
          </Title>
        </TabPane>
        <TabPane tab="Actualités" key="3">
          <Title level={4}>
            <EventComponent />
          </Title>
        </TabPane>
        <TabPane tab="Les maladies du sang" key="4">
          <Title level={4}>
            <DiseaseComponent />
          </Title>
        </TabPane>
        <TabPane tab="Les psychologues" key="5">
          <Title level={4}>
            <PsychologistComponent />
          </Title>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Atgmo;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Empty, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import {
  deleteQuestionAndAnswer,
  fetchAllQuestionAndAnswers,
  selectAllQuestionAndAnswers,
} from '../../reducers/questionAndAnswer.slice';

// local components
import QuestionAndAnswerForm from './QuestionAndAnswerForm';

/* -------------------------------------------------------------------------- */
/*                          Question And Answer Page                          */
/* -------------------------------------------------------------------------- */
function QuestionAndAnswerComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const [loading, setLoading] = useState(true);
  // Selectors
  const QuestionAndAnswerObject = useSelector(selectAllQuestionAndAnswers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllQuestionAndAnswers());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeQuestionAndAnswer = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${data?.question_fr}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteQuestionAndAnswer(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Supprimer la question et la réponse',
              description: 'La question et la réponse ont été supprimées avec succès',
            });
            dispatch(fetchAllQuestionAndAnswers());
          })
          .catch((err) =>
            notification.error({
              message: 'Supprimer la question et la réponse',
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const Q_A = QuestionAndAnswerObject && QuestionAndAnswerObject.questionAndAnswers;

  const Q_A_DATA = Q_A?.map((qa, index) => ({
    // in common
    key: index,
    _id: qa._id,
    link: qa.link,
    // for arab lang
    question_ar: qa.ar.question,
    answer_ar: qa.ar.answer,
    // for french lang
    question_fr: qa.fr.question,
    answer_fr: qa.fr.answer,
  }));

  const Q_A_COLUMN = [
    {
      title: 'Question',
      key: 'question_fr',
      dataIndex: 'question_fr',
    },
    {
      title: 'Réponse',
      key: 'answer_fr',
      dataIndex: 'answer_fr',
      render: (ANSWER_FR) => v.truncate(ANSWER_FR, 20),
    },
    {
      title: 'Link',
      key: 'link',
      dataIndex: 'link',
      render: (link) => (
        <Link target="_blank" to={{ pathname: `${link}` }}>
          {link}
        </Link>
      ),
    },
    {
      title: 'إجابه',
      key: 'answer_ar',
      dataIndex: 'answer_ar',
      render: (ANSWER_AR) => v.truncate(ANSWER_AR, 20),
    },
    {
      title: 'سؤال',
      key: 'question_ar',
      dataIndex: 'question_ar',
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <QuestionAndAnswerForm record={record} />
          </Col>
          <Col className="mr">
            <Button type="danger" onClick={() => removeQuestionAndAnswer(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {Q_A_DATA?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucune question ni réponse n'a été trouvée"
            >
              <QuestionAndAnswerForm label="Créer une nouvelle question et réponse" isCreatedForm />
            </Empty>
          ) : (
            <>
              <QuestionAndAnswerForm label="Créer une nouvelle question et réponse" isCreatedForm />
              <Table columns={Q_A_COLUMN} dataSource={Q_A_DATA} scroll={{ x: 1400 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default QuestionAndAnswerComponent;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Avatar, Empty } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { fetchAllUsers, selectAllUsers, deleteUser } from '../../reducers/User.slice';

// Local Components
import ManagerForm from './ManagerForm';
import Loader from '../../shared/Components/Loader';

// helpers
import { RetriveUserAvatar } from '../../common/helpers';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function ManagerList() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const [loading, setLoading] = useState(true);
  // Selectors
  const users = useSelector(selectAllUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeUser = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer le manager < ${data?.fullName} > ?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (data.is_admin) {
          notification.error({
            message: 'Supprimer manager',
            description: "vous ne pouvez pas supprimer l'administrateur, vous ne pouvez que le mettre à jour",
          });
        } else {
          dispatch(deleteUser(data._id))
            .then(unwrapResult)
            .then(() => {
              notification.success({
                message: 'Supprimer manager',
                description: 'Le manager a été supprimé avec succès',
              });
              dispatch(fetchAllUsers());
            })
            .catch((err) =>
              notification.error({
                message: 'Supprimer manager',
                description: err.error.message,
              }),
            );
        }
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const allUsers = users?.user?.filter((user) => user.is_manager === true);

  const usersData = allUsers?.map((user, index) => ({
    key: index,
    _id: user?._id,
    fullName: user?.fullName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    governorate: user?.governorate,
    is_active: user?.is_active,
    municipality: user?.municipality,
    gender: user?.gender,
    photo: user?.photo,
    role: Object.keys(user).filter((k) => user[k] === true),
  }));

  const USER_COLUMN = [
    {
      title: 'Avatar',
      key: 'photo',
      dataIndex: 'photo',
      render: (_i, user) => <Avatar size={64} src={RetriveUserAvatar(user)} />,
    },
    {
      title: 'Nom et prénom',
      key: 'fullName',
      dataIndex: 'fullName',
      fixed: 'left',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Téléphone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Gouvernorat',
      key: 'governorate',
      dataIndex: 'governorate',
    },
    {
      title: 'Municipalité',
      key: 'municipality',
      dataIndex: 'municipality',
    },
    {
      title: 'Compte',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (active) => (active === true ? 'Compte activé' : 'Compte désactivé '),
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <ManagerForm record={record} />
          </Col>
          <Col className="mr">
            <Button onClick={() => removeUser(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {allUsers?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucun manager n'a été trouvé"
            >
              <ManagerForm label="créer un nouvel manager" isCreatedForm />
            </Empty>
          ) : (
            <>
              <ManagerForm label="créer un nouvel manager" isCreatedForm />
              <Table columns={USER_COLUMN} dataSource={usersData} scroll={{ x: 1100 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ManagerList;

/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All blood bank
export const fetchAllBloodBanks = createAsyncThunk('bloodBank/fetchAllBloodBanks', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/blood/banks`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch single blood bank object
export const fetchBloodBank = createAsyncThunk('bloodBank/fetchBloodBank', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/blood-banks/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create new blood bank
export const createBloodBank = createAsyncThunk('bloodBank/createBloodBank', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/v1/api/blood/bank`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Update blood Bank
export const updateBloodBank = createAsyncThunk('bloodBank/updateBloodBank', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/v1/api/blood/banks/${data._id}`,
      data: data.fields,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Delete blood Bank
export const deleteBloodbank = createAsyncThunk('bloodBank/deleteBloodbank', async (_id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/v1/api/blood/banks/${_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// BloodBank slice
const BloodBank = createSlice({
  name: 'BloodBank',
  initialState: {
    bloodBanks: [],
    bloodBank: null,
    loading: false,
  },
  extraReducers: {
    [fetchAllBloodBanks.fulfilled]: (state, action) => {
      state.bloodBanks = action.payload;
      state.loading = false;
    },
    [fetchAllBloodBanks.pending]: (state) => {
      state.loading = true;
    },
    [fetchBloodBank.fulfilled]: (state, action) => {
      state.bloodBank = action.payload;
      state.loading = false;
    },
    [fetchBloodBank.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default BloodBank.reducer;

// Selectors
export const selectAllBloodBanks = (state) => state.BloodBank.bloodBanks;
export const selectBloodBank = (state) => state.BloodBank.bloodBank;
export const selectBloodBankLoading = (state) => state.BloodBank.loading;

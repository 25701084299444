/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// redux
import { useSelector } from 'react-redux';

// UI Components
import { Tabs } from 'antd';

// Local Components
import ManagerList from './managersList';
import UserList from './usersList';

// reducers
import { selectSessionUser } from '../../reducers/Session.slice';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function UserPage() {
  /* ---------------------------------- CONST --------------------------------- */
  const { TabPane } = Tabs;
  const user = useSelector(selectSessionUser);

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
        {user && user.is_admin && (
          <TabPane tab="Listes des managers" key="1">
            <ManagerList />
          </TabPane>
        )}
        <TabPane tab="Liste des utilisateurs" key="2">
          <UserList />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserPage;

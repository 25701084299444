/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All question and answer
export const fetchAllQuestionAndAnswers = createAsyncThunk(
  'questionAndAnswer/fetchAllQuestionAndAnswers',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/questions-answers`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// fetch single question and answer object
export const fetchQuestionAndAnswer = createAsyncThunk(
  'questionAndAnswer/fetchQuestionAndAnswer',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/questions-answers/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Create new question and answer
export const createQuestionAndAnswer = createAsyncThunk(
  'questionAndAnswer/createQuestionAndAnswer',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/v1/api/question-answer`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update questionAndAnswer
export const updateQuestionAndAnswer = createAsyncThunk(
  'questionAndAnswer/updateQuestionAndAnswer',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/v1/api/questions-answers/${data._id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Delete questionAndAnswer
export const deleteQuestionAndAnswer = createAsyncThunk(
  'questionAndAnswer/deleteQuestionAndAnswer',
  async (_id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/v1/api/questions-answers/${_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// QuestionAndAnswer slice
const QuestionAndAnswer = createSlice({
  name: 'QuestionAndAnswer',
  initialState: {
    questionAndAnswers: [],
    questionAndAnswer: null,
    loading: false,
  },
  extraReducers: {
    [fetchAllQuestionAndAnswers.fulfilled]: (state, action) => {
      state.questionAndAnswers = action.payload;
      state.loading = false;
    },
    [fetchAllQuestionAndAnswers.pending]: (state) => {
      state.loading = true;
    },
    [fetchQuestionAndAnswer.fulfilled]: (state, action) => {
      state.questionAndAnswer = action.payload;
      state.loading = false;
    },
    [fetchQuestionAndAnswer.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default QuestionAndAnswer.reducer;

// Selectors
export const selectAllQuestionAndAnswers = (state) => state.QuestionAndAnswer.questionAndAnswers;
export const selectQuestionAndAnswer = (state) => state.QuestionAndAnswer.questionAndAnswer;
export const selectQALoading = (state) => state.QuestionAndAnswer.loading;

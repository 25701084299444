/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import v from 'voca';
import _ from 'underscore';

// UI Components
import { Statistic, Row, Col, Skeleton, Card, Avatar, Carousel, Empty, List } from 'antd';

// Reducers
import { fetchAllUsers, selectAllUsers } from '../../reducers/User.slice';
import { fetchAllHelpRequests, selectHR } from '../../reducers/HelpRequest.slice';
import { fetchAllDonnations } from '../../reducers/Donnation.slice';
import { fetchAllEvents, selectAllEvents } from '../../reducers/Event.slice';
import { fetchAllBloodBanks } from '../../reducers/BloodBank.slice';

// helpers, config && constants
import { API_ENDPOINT } from '../../common/config';
import { localMoment, RetriveImage, RetriveUserAvatar } from '../../common/helpers';
import { dateFormat } from '../../common/constants';

// Local images
import EMPTY from '../../assets/empty.png';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function Home() {
  /* ---------------------------------- HOOKS --------------------------------- */
  // Selectors
  const dispatch = useDispatch();
  const users = useSelector(selectAllUsers);
  const HELP_REQUEST_OBJECT = useSelector(selectHR);
  const AllEvents = useSelector(selectAllEvents);
  const [loading, setLoading] = useState(true);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimenion]);

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchAllHelpRequests());
    dispatch(fetchAllDonnations());
    dispatch(fetchAllEvents());
    dispatch(fetchAllBloodBanks());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ---------------------------------- CONST --------------------------------- */
  const { Meta } = Card;

  const totalBloodRequest = HELP_REQUEST_OBJECT.helpRequests?.totalBloodRequest;
  const totalMaterialRequest = HELP_REQUEST_OBJECT.helpRequests?.totalMaterialRequest;
  const allUsers = users?.user?.filter((user) => !user.is_admin && !user.is_manager);
  const totalDonners = allUsers?.filter((user) => user.is_doner);
  const totalVolunteer = allUsers?.filter((user) => user.is_volunteer);

  const events = AllEvents?.events;
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const sortUsersByBloodCount = allUsers?.sort((a, b) => {
    return a.bloodHelpCount - b.bloodHelpCount;
  });
  const sortUsersByMaterialCount = allUsers?.sort((a, b) => {
    return a.materialHelpCount - b.materialHelpCount;
  });

  function GetStatistic() {
    return (
      <div>
        <Card className="home-statistic">
          {windowDimenion.winWidth < '850' && (
            <Row align="bottom" justify="space-around">
              <Col>
                <Statistic title="Total utilisateurs" value={allUsers?.length} />
              </Col>
              <Col>
                <Statistic title="Total donneurs" value={totalDonners?.length} className="mt" />
              </Col>
              <Col>
                <Statistic title="Total bénévoles" value={totalVolunteer?.length} className="mt" />
              </Col>
            </Row>
          )}
          <Row align="top" justify="space-around">
            {windowDimenion.winWidth > '850' && (
              <Col>
                <Statistic title="Total utilisateurs" value={allUsers?.length} />
                <Statistic title="Total donneurs" value={totalDonners?.length} className="mt" />
                <Statistic title="Total bénévoles" value={totalVolunteer?.length} className="mt" />
              </Col>
            )}
            <Col>
              <Statistic title="Total demandes de parrainage " value={totalMaterialRequest} />
            </Col>
            <Col>
              <Statistic title="Total dons de vie" value={totalBloodRequest} />
            </Col>
            <Col>
              <Statistic title="Total actualités" value={events?.length} />
            </Col>
          </Row>
        </Card>
        <Row align="top" justify="space-around">
          <Col>
            <h1 className="mt">
              <strong>Les donneurs les plus actifs</strong>
            </h1>
            <List
              itemLayout="horizontal"
              dataSource={_.sortBy(sortUsersByBloodCount, 'bloodHelpCount').reverse()?.slice(0, 3)}
              renderItem={(user) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={64} src={RetriveUserAvatar(user)} />}
                    title={user?.fullName}
                    description={`Total dons de vie accepté: ${user?.bloodHelpCount}`}
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col>
            <h1 className="mt">
              <strong>Les bénévoles les plus actifs</strong>
            </h1>
            <List
              itemLayout="horizontal"
              dataSource={_.sortBy(sortUsersByMaterialCount, 'materialHelpCount').reverse()?.slice(0, 3)}
              renderItem={(user) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={64} src={RetriveUserAvatar(user)} />}
                    title={user?.fullName}
                    description={`Total parrainage accepté: ${user?.materialHelpCount}`}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    );
  }

  function GetActualities() {
    return (
      <div>
        <h1 className="mt">
          <strong>Dernières actualités</strong>
        </h1>
        <div className="site-card-border-less-wrapper mt">
          {events?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              description="Aucune actualité prévue"
            />
          ) : (
            <Row justify="start" align="top" gutter={12}>
              {_.sortBy(events, 'createdAt')
                .reverse()
                ?.slice(0, 4)
                .map((event, index) => (
                  <Col xs={20} sm={20} md={12} lg={6} key={index} className="mt">
                    <Card
                      style={{ borderRadius: '20px' }}
                      cover={
                        <Carousel effect="fade">
                          {event.gallery?.length <= 1 ? (
                            <img className="max-img-height" src={EMPTY} alt="no gallery" />
                          ) : (
                            event.gallery?.map((photo, i) => (
                              <div key={i}>
                                <img className="max-img-height" src={`${API_ENDPOINT}/${photo}`} />
                              </div>
                            ))
                          )}
                        </Carousel>
                      }
                    >
                      <Meta
                        avatar={<Avatar size={42} src={RetriveImage(event?.photo)} />}
                        title={v.truncate(event.fr?.event_name, 50)}
                        description={v.truncate(event.fr?.description, 100)}
                      />
                      <div className="mt">
                        <p>
                          <strong>Lieu</strong> {v.truncate(event.fr?.event_location, 50)}
                        </p>
                        <p>
                          <strong>Start At</strong> {event.start_at && localMoment(event.start_at).format(dateFormat)}
                        </p>
                        {event.end_at && (
                          <p>
                            <strong>End At</strong> {event.end_at && localMoment(event.end_at).format(dateFormat)}
                          </p>
                        )}
                        {event?.link && (
                          <strong>
                            Link{' '}
                            <Link target="_blank" to={{ pathname: `${event?.link}` }}>
                              {event?.link}
                            </Link>
                          </strong>
                        )}
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          )}
        </div>
      </div>
    );
  }

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {GetStatistic()}
          {windowDimenion.winWidth > '850' && GetActualities()}
        </>
      )}
    </div>
  );
}

export default Home;

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */
// process
export const {
  REACT_APP_DEVELOPMENT_API_URL,
  REACT_APP_PRODUCTION_API_URL,
  REACT_APP_LOCATION_PLACES_API_KEY,
  REACT_APP_DEFAULT_DATE_FORMAT,
} = process.env;

/**
 * @constant dateFormat = In Tunisia/France, the all-numeric form for dates is
 * in the order "day month year"
 */
export const dateFormat = REACT_APP_DEFAULT_DATE_FORMAT;

/* -------------------------------------------------------------------------- */
/*                            HEADERS IN EXPORT CSV                           */
/* -------------------------------------------------------------------------- */
/**
 * Headers are the columns of csv file
 * Label is the column name
 * Key is for mapping data with the corresponding column
 */
export const EXPORT_CSV_HEADERS = [
  { label: 'Nom et prénom', key: 'fullName' },
  { label: 'Genre', key: 'gender' },
  { label: 'Age', key: 'age' },
  { label: 'Date de naissance', key: 'dateOfBirth' },
  { label: 'E-mail', key: 'email' },
  { label: 'Numéro de téléphone', key: 'phoneNumber' },
  { label: 'Gouvernorat', key: 'governorate' },
  { label: 'Municipalité', key: 'municipality' },
  { label: 'Statut', key: 'status' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Updated At', key: 'updatedAt' },
];
/* -------------------------------------------------------------------------- */
/*                                   STATUS                                   */
/* -------------------------------------------------------------------------- */
/**
 * Retrieve emergency status for both French and Arabic language
 */
export const status = ['Critique', 'Forte', 'Moyenne', 'Faible'];
export const STATUS_AR = ['حرج', 'قوي', 'متوسط', 'ضعيف'];

/* -------------------------------------------------------------------------- */
/*                             SECTOR AND SERVICE                             */
/* -------------------------------------------------------------------------- */
/**
 * Retrieve sector and service for both French and Arabic language
 * @constant SECTOR : is sector in french language
 * @constant SECTOR_T_AR : is sector in arabic language
 * @constant SECTOR_DATA : service for each sector in French language
 * @constant SECTOR_DATA_AR : service for each sector in arabic language
 */
export const SECTOR = [
  'Santé',
  'Soins',
  'Assistances',
  'Aides financières',
  'Dons de matériels',
  'Education',
  'Bénévolat',
  'Maintenance et télémaintenance',
  'Textile',
  'Meubles',
  'Employabilité des greffés',
];
export const SECTOR_T_AR = [
  'الصحة',
  'الرعاية',
  'المساعدات',
  'المساعدة المالية',
  'التبرعات المادية',
  'التعليم',
  'التطوع',
  'الصيانة والصيانة عن بُعد',
  'المنسوجات',
  'الأثاث',
  'قابلية توظيف متلقي الزرع',
];

export const SECTOR_DATA = {
  Santé: ['Achats de traitements', 'Examens médicaux'],
  Soins: ['Soins infirmiers', 'Kinésithérapie', 'Esthétique', 'Coiffure'],
  Assistances: ['Transport', 'Covoiturage'],
  'Aides financières': ['Parrainer un malade'],
  'Dons de matériels': ['Masques/gel', 'Produits d’entretien', 'Produits alimentaires'],
  Education: ['Cours de soutien', 'Livres/ fournitures scolaires', 'Lecture et peinture', 'Musique'],
  Bénévolat: ['Organisation des événements', 'Tâches administratives', 'Faire des courses pour les malades'],
  'Maintenance et télémaintenance': ['Plomberie', 'Electricité ', 'Réparation TV ', 'Réparation appareil électronique'],
  Textile: ['Vêtements pour les malades', 'Literie', 'Rideaux', 'Matelas et sommiers', 'Tapis'],
  Meubles: ['Dons de meubles'],
  'Employabilité des greffés': ['Offres d’emploi pour les greffés'],
};
export const SECTOR_DATA_AR = {
  الصحة: ['شراء ادوية ', 'الفحوصات الطبية'],
  الرعاية: ['رعاية تمريضية', 'علاج طبيعي', 'التجميل', 'تصفيف شعر'],
  المساعدات: ['النقل', 'مشاركة السيارات'],
  'المساعدة المالية': ['التكفل بمريض'],
  'التبرعات المادية': ['أقنعة / جل', 'منتجات التنظيف', 'منتجات غذائية'],
  التعليم: ['دروس الدعم', 'الكتب / اللوازم المدرسية', 'القراءة والرسم', 'الموسيقى'],
  التطوع: ['تنظيم التظاهرات', 'المهام الإدارية', 'أداء المهمات للمرضى'],
  'الصيانة والصيانة عن بُعد': ['السباكة', 'الكهرباء', 'إصلاح التلفزيون', 'إصلاح الأجهزة الإلكترونية'],
  المنسوجات: ['ملابس للمرضى', 'الافرشة', 'الستائر', 'المراتب والنوابض الصندوقية', 'السجاد'],
  الأثاث: ['التبرع  بالاثاث'],
  'قابلية توظيف متلقي الزرع': ['عروض عمل لمتلقي الزرع'],
};

/* -------------------------------------------------------------------------- */
/*                        GOVERNORATE AND MUNICIPALITY                        */
/* -------------------------------------------------------------------------- */
/**
 * Retrieve governorate and municipality for both French and Arabic language
 * @constant region : is governorate in french language
 * @constant REGION_AR : is governorate in arabic language
 * @constant GOUVERNORAT_DATA : municipality for each governorate in French language
 * @constant GOUVERNORAT_DATA_AR : municipality for each governorate in arabic language
 */
export const region = [
  'Tous',
  'Tunis',
  'Ariana',
  'Beja',
  'Ben Arous',
  'Bizerte',
  'Gabes',
  'Gafsa',
  'Jendouba',
  'Kairouan',
  'Kasserine',
  'Kebili',
  'Kef',
  'Mahdia',
  'Manouba',
  'Mednine',
  'Monastir',
  'Nabeul',
  'Siliana',
  'Sfax',
  'Sidi Bouzid',
  'Sousse',
  'Tataouine',
  'Tozeur',
  'Zaghouan',
];
export const REGION_AR = [
  'الجميع',
  'تونس',
  'أريانة',
  'باجة',
  'بن عروس',
  'بنزرت',
  'قابس',
  'قفصة',
  'جندوبة',
  'القيروان',
  'القصرين',
  'قبلي',
  'الكاف',
  'المهدية',
  'منوبة',
  'مدنين',
  'المنستير',
  'نابل',
  'سليانة',
  'صفاقس',
  'سيدي بوزيد',
  'سوسة',
  'تطاوين',
  'توزر',
  'زغوان',
];
export const GOUVERNORAT_DATA = {
  Tous: ['Tous'],
  Tunis: [
    'Toutes',
    'Tunis',
    'Sidi Hassine',
    'La Marsa',
    'Le Kram',
    'Bardo',
    'La Goulette',
    'Carthage',
    'Sidi Bou Said',
  ],
  Ariana: ['Toutes', 'Soukra', 'Ariana', 'Raoued', 'Mnihla', 'Ettadhamen', 'Kalaat El-Andalous', 'Sidi Thabet'],
  Beja: [
    'Toutes',
    'Beja',
    'Medjez El-Bab',
    'Testour',
    'Nefza',
    'Teboursouk',
    'Ouachtata El-Jamila',
    'Zahret Medien',
    'Goubellat',
    'El-Maagoula',
    'Tibar',
    'Sidi Smail',
    'El-Slouguia',
  ],
  'Ben Arous': [
    'Toutes',
    'El-Mourouj',
    'Ben Arous',
    'Mohamedia',
    'Rades',
    'Fouchana',
    'Hammam-Lif',
    'Mornag',
    'Boumhel El-Bassatine',
    'El-Zahra',
    'Hammem Chatt',
    'Naassen',
    'Megrine',
    'Khelidia',
  ],
  Bizerte: [
    'Toutes',
    'Bizerte',
    'Menzel Bourguiba',
    'Mateur',
    'Ras Jebel',
    'Joumine',
    'Menzel Jemil',
    'El-Alia',
    'Ghezala',
    'Tinja',
    'Sejnane',
    'Utique',
    'Menzel Abderrahmen',
    'El-Hachachna',
    'Metline',
    'Rafraf',
    'Ousja',
    'Ghar El-Meleh',
  ],
  Gabes: [
    'Toutes',
    'Gabes',
    'El-Hamma',
    'Mareth',
    'Ghanouch',
    'Teboulbou',
    'Habib Thameur - Bouattouch',
    'Chenini Nahal',
    'Ouedhref',
    'Nouvelle Matmata',
    'Ketana',
    'El-Metouia',
    'Bouchemma',
    'Dekhilet Toujane',
    'Menzel Habib',
    'Ezzarat',
    'Matmata',
  ],
  Gafsa: [
    'Toutes',
    'Gafsa',
    'Metlaoui',
    'El-Ksar',
    'Redeyef',
    'Oum El-Araies',
    'Sned',
    'El-Guetar',
    'Mdhila',
    'Sanouche',
    'Belkhir',
    'Sidi Aïch',
    'Lala',
    'Sidi Boubaker',
  ],
  Jendouba: [
    'Toutes',
    'Jendouba',
    'Bou Salem',
    'Ghardimaou',
    'Souk El-Sebt',
    'Tabarka',
    'Balta - Bou Aouane',
    'Fernana',
    'Ain Drahem',
    'Oued Meliz',
    'Jaouaouada',
    'Ain El-Sobh - Nadhour',
    'El-Galaa - El-Maaden',
    'Khemairia',
    'Beni Mtir',
  ],
  Kairouan: [
    'Toutes',
    'Kairouan',
    'Bouhajla',
    'Sbikha',
    'Sisseb Dheriaat',
    'El-Alaa',
    'Oueslatia',
    'Hafouz',
    'Jehina',
    'Hajeb El-Ayoun',
    'Rakada',
    'Nasrallah',
    'Echbika',
    'Abida',
    'Echrarda',
    'Cheraitia El-Ksour',
    'Aïn El-Beidha',
    'Menzel Mhiri',
    'Chaouachi',
    'Aïn Jeloula',
  ],
  Kasserine: [
    'Toutes',
    'Ennour',
    'Thala',
    'Sbeitla',
    'Feriana',
    'Kasserine',
    'Foussana',
    'Charaya Machrek El-Chams',
    'Majel Bel-Abbes',
    'Sbiba',
    'El-Zouhour',
    'Hassi El-Ferid',
    'El-Ayoun',
    'Aïn El-Khemaïssia',
    'Thelebet',
    'Khmouda',
    'El-Rakhmet',
    'Bouzguem',
    'Jedliane',
    'Hidra',
  ],
  Kebili: [
    'Toutes',
    'Kebili',
    'Douz',
    'Souk El-Ahad',
    'El-Faouar',
    'Bechelli - Jersine - Blidete',
    'Béchri - Fatnassa',
    'El-Golaa',
    'Jemna',
    'Rejim Maatoug',
  ],
  Kef: [
    'Toutes',
    'El-Kef',
    'Dahmani',
    'Es-Seres',
    'Tejrouine',
    'Sakiet Sidi Youssef',
    'Kalaat Senan',
    'El-Ksour',
    'Nebeur',
    'Zaafrane - Dyr El-Kef',
    'Djerissa',
    'Menzel Salem',
    'Kalaat Khasba',
    'Touiref',
    'Bahra',
    'Marja',
  ],
  Mahdia: [
    'Toutes',
    'Mahdia',
    'Ksour Essaf',
    'El-Jem',
    'Essouassi',
    'Chorbane',
    'Sidi Alouane',
    'Chebba',
    'Ouled Chamekh',
    'Melloulech',
    'Sidi Zid - Ouled Moulahem',
    'El-Bradaa',
    'Kerker',
    'Boumerdes',
    'Hekaima',
    'Telalsa',
    'Zelba',
    'Hebira',
    'Rejiche',
  ],
  Manouba: [
    'Toutes',
    'Douar Hicher',
    'Oued Ellil',
    'Jedaida',
    'Tebourba',
    'Manouba',
    'Mornaguia',
    'Denden',
    'Battane',
    'Borj El-Amri',
    'El-Bassatine',
  ],
  Mednine: [
    'Toutes',
    'Mednine',
    'Houmet Souk',
    'Midoun',
    'Beni Guerdane',
    'Zarzis',
    'Zarzis Nord',
    'Beni Khedech',
    'Sidi Makhlouf',
    'Ajim',
    'Boughrara',
  ],
  Monastir: [
    'Toutes',
    'Monastir',
    'Moknine',
    'Jamel',
    'Ksar Helal',
    'Teboulba',
    'Ouerdanine',
    'Sahline',
    'Bekalta',
    'Zeramdine',
    'Bembla',
    'Benen',
    'Ksibet El-Mediouni',
    'Sayada',
    'Menzel Hayet',
    'Menzel Nour',
    'Khenis',
    'Beni Hassen',
    'Sidi Ameur',
    'Menzel Kamel',
    'Amiret El-Hojaj',
    'Touza',
    'Zaouiet Kontoch',
    'Amiret Touazra',
    'Bouhjar',
    'Lamta',
    'Amiret El-Fehoul',
    'Ghnada',
    'Menzel Harb',
    'Sidi Bennour',
    'Chérahil',
    'Menzel Farsi',
  ],
  Nabeul: [
    'Toutes',
    'Hammamet',
    'Nabeul',
    'Korba',
    'Menzel Temime',
    'Kelibia',
    'Dar Chaabane',
    'Grombalia',
    'Soliman',
    'Menzel Bouzelfa',
    'Bouargoub',
    'El-Mida',
    'Beni Khalled',
    'Haouaria',
    'Beni Khiar',
    'Fondek Jedid - Selten',
    'Takelsa',
    'Menzel Horr',
    'Tazaghrane - Boukrim - Zaouiet El-Megaiez',
    'Sidi Jedidi',
    'El-Cherifet - Boucharrai',
    'Zaouiet Jedidi',
    'El-Maamoura',
    'Azmour',
    'Hammam El-Ghezaz',
    'Tazerka',
    'Somaa',
    'Dar Allouch',
    'Korbous',
  ],
  Siliana: [
    'Toutes',
    'Siliana',
    'Makther',
    'Bouarada',
    'El-Krib',
    'Gaafour',
    'Kesra',
    'Rouhia',
    'Sidi Morched',
    'Bourouis',
    'Bargou',
    'Hebabsa',
    'Aroussa',
  ],
  Sfax: [
    'Toutes',
    'Sfax',
    'Sakiet Ezzit',
    'Sakiet Eddaier',
    'Tyna',
    'El-Aïn',
    'Agareb',
    'Gremda',
    'El-Aouabed Khazanet',
    'Skhira',
    'Mahrès',
    'Hencha',
    'Bir Ali Ben Khelifa',
    'Jebeniana',
    'El-Amra',
    'Chihia',
    'Nadhour Sidi Ali Belabed',
    'Menzel Chaker',
    'Hajeb',
    'Hazeg El-Louza',
    'El-Achech - Bou Jarboue - El-Aouadhna - Majel Edarj',
    'En-Nasr',
    'El-Ghraiba',
    'Kerkenah',
  ],
  'Sidi Bouzid': [
    'Toutes',
    'Sidi Bouzid',
    'Regueb',
    'Menzel Bouzaïane',
    'Lassouada',
    'Mezzouna',
    'Bir El-Hafey',
    'Jelma',
    'El-Faiedh Bennour',
    'Meknassy',
    'Essaïda',
    'Souk Jedid',
    'Cebalet Ouled Asker',
    'Ouled Haffouz',
    'Sidi Ali Ben Aoun',
    'Batan El-Ghézel',
    'Rahal',
    'Mansoura',
  ],
  Sousse: [
    'Toutes',
    'Sousse',
    'Msaken',
    'Ezzouhour',
    'Kalaa Kebira',
    'Hammam Sousse',
    'Kalaa Seghira',
    'Enfidha',
    'Akouda',
    'Bouficha',
    'Gerimet-Hicher',
    'Zaouit Sousse',
    'Sidi Bou Ali',
    'Kondar',
    'Sidi El-Héni',
    'Messaadine',
    'Ksibet-Thrayet',
    'Hergla',
    'Chott Meriam',
  ],
  Tataouine: [
    'Toutes',
    'Tataouine',
    'Ghomrassen',
    'El-Smar',
    'Remada',
    'Bir Lahmar',
    'Chenini - El-Douiret',
    'Dhehbia',
  ],
  Tozeur: ['Toutes', 'Tozeur', 'Nefta', 'Degach', 'Hamma Jerid', 'Tameghza', 'Hazoua'],
  Zaghouan: [
    'Toutes',
    'Zaghouan',
    'El-Fahs',
    'En-Nadhour',
    'Zeriba',
    'Bir Mchergua',
    'Saouef',
    'El Amaiem',
    'Jebel Oust',
  ],
};
export const GOUVERNORAT_DATA_AR = {
  الجميع: ['الجميع'],
  تونس: ['الجميع', 'تونس', 'سيدي حسين', 'المرسى', 'الكرم', 'باردو', 'حلق الوادي', 'قرطاج', 'سيدي بوسعيد'],
  أريانة: ['الجميع', 'سكرة', 'أريانة', 'رواد', 'المنيهلة', 'التضامن', 'قلعة الأندلس', 'سيدي ثابت'],
  باجة: [
    'الجميع',
    'باجة',
    'مجاز الباب',
    'تستور',
    'نفزة',
    'تبرسوق',
    'وشتاتة الجميلة',
    'زهرة مديان',
    'قبلاط',
    'المعقولة',
    'تيبار',
    'سيدي إسماعيل',
    'السلوقية',
  ],
  'بن عروس': [
    'الجميع',
    'المروج',
    'بن عروس',
    'المحمدية',
    'رادس',
    'فوشانة',
    'حمام الأنف',
    'مرناق',
    'بومهل البساتين',
    'الزهراء',
    'حمام الشط',
    'نعسان',
    'مقرين',
    'خليدية',
  ],
  بنزرت: [
    'الجميع',
    'بنزرت',
    'منزل بورقيبة',
    'ماطر',
    'راس جبل',
    'جومين',
    'منزل جميل',
    'عالية',
    'غزالة',
    'تينجا',
    'سجنان',
    'يوتيك',
    'منزل عبد الرحمن',
    'الحشاشنة',
    'ميتلين',
    'رفراف',
    'عوسجة',
    'غار الملح',
  ],
  قابس: [
    'الجميع',
    'قابس',
    'الحامة',
    'مارث',
    'غنوش',
    'طبلبو',
    'الحبيب ثامر -بوعطوش',
    'شنيني نحل',
    'وذرف',
    'مطماطة جديدة',
    'كتانة',
    'المطوية',
    'بوشامة',
    'دخيلة توجان',
    'منزل حبيب',
    'الزارات',
    'مطماطة',
  ],
  قفصة: [
    'الجميع',
    'قفصة',
    'المتلوي',
    'القصر',
    'الرديف',
    'أم العرابس',
    'سند',
    'القطار',
    'مظيلة',
    'سنوش',
    'بلخير',
    'سيدي عيش',
    'لالة',
    'سيدي بوبكر',
  ],
  جندوبة: [
    'الجميع',
    'جندوبة',
    'بو سالم',
    'غار الدماء',
    'سوق السبت',
    'طبرقة',
    'بلطة-بوعون',
    'فرنانة',
    'عين دراهم',
    'واد مليز',
    'الجواودة',
    'عين الصبح-الناظور',
    'القلعة-المعادن',
    'خمايرية',
    'بني مطير',
  ],
  القيروان: [
    'الجميع',
    'القيروان',
    'بوحجلة',
    'سبيخة',
    'سيسب ضريعات',
    'العلا',
    'الوسلاتية',
    'حفوز',
    'جهينة',
    'حاجب العيون',
    'رقادة',
    'نصرالله',
    'الشبيكة',
    'عبيدة',
    'الشراردة',
    'شرايطية القصور',
    'عين البيضاء',
    'منزل مهيري',
    'الشواشي',
    'عين جلولة',
  ],
  القصرين: [
    'الجميع',
    'النور',
    'تالة',
    'سبيطلة',
    'فريانة',
    'القصرين',
    'فوسانة',
    'الشرعية مشرق الشمس',
    'مجلس بالعباس',
    'سبيبة',
    'الزهور',
    'حاسي الفريد',
    'العيون',
    'عين الخمايسية',
    'ثلابت',
    'خمودة',
    'الرخمات',
    'بوزقم',
    'جدليان',
    'حيدرة',
  ],
  قبلي: [
    'الجميع',
    'قبلي',
    'دوز',
    'سوق الأحد',
    'الفوار',
    'بشيلي - جرسين - بليدات',
    'بشري - فطنسة',
    'القلة',
    'جيمنا',
    'رجيم معتوق',
  ],
  الكاف: [
    'الجميع',
    'الكاف',
    'دهماني',
    'السرس',
    'تاجروين',
    'ساقية سيدي يوسف',
    'قلعة سنان',
    'القصور',
    'نبر',
    'زعفران - دير الكاف',
    'الجريصة',
    'منزل سالم',
    'قلعة خصبة',
    'طويرف',
    'بهرة',
    'المرجة',
  ],
  المهدية: [
    'الجميع',
    'المهدية',
    'قصور الساف',
    'الجم',
    'السواسي',
    'شربان',
    'سيدي علوان',
    'شابة',
    'أولاد شامخ',
    'ملولش',
    'سيدي زيد - أولاد مولاهم',
    'البرادعة',
    'كركر',
    'بومرداس',
    'حكايمة ',
    'تلالسة',
    'زلبة',
    'هبيرة',
    'رجيش',
  ],
  منوبة: [
    'الجميع',
    'دوار هيشر',
    'واد الليل',
    'الجديدة',
    'طبربة',
    'منوبة',
    'المرناقية',
    'دندان',
    'البطان',
    'برج العامري',
    'البساتين',
  ],
  مدنين: [
    'الجميع',
    'مدنين',
    'حومة السوق',
    'ميدون',
    'بن قردان',
    'جرجيس',
    'جرجيس الشمالية',
    'بني خديش',
    'سيدي مخلوف',
    'أجيم',
    'بوغرارة',
  ],
  المنستير: [
    'الجميع',
    'المنستير',
    'المكنين',
    'جمال',
    'قصر هلال',
    'طبلبة',
    'الوردانين',
    'الساحلين',
    'البقالطة',
    'زرمدين',
    'بنبلة',
    'بنان',
    'قصيبة المديوني',
    'صيادة',
    'منزل حياة',
    'منزل نور',
    'خنيس',
    'بني حسان',
    'سيدي عامر',
    'منزل كامل',
    'عميرة الحجاج',
    'طوزة',
    'زاوية قنتش',
    'عميرة التوازرة',
    'بوحجر',
    'لمطة',
    'عميرة الفحول',
    'غنادة',
    'منزل حرب',
    'سيدي بنور',
    'شراحيل',
    'منزل الفارسي',
  ],
  نابل: [
    'الجميع',
    'الحمامات',
    'نابل',
    'قربه',
    'منزل تميم',
    'قليبية',
    'دار شعبان',
    'قرمبالية',
    'سليمان',
    'منزل بوزلفة',
    'بوعرقوب',
    'الميدا',
    'بني خلاّد',
    'الهوارية',
    'بني خيار',
    'الفندق الجديد - سلتان',
    'تاكلسة',
    'منزل الحر',
    'تزغران - بوكريم - زاوية المجيز',
    'سيدي الجديدي',
    'الشريفات-بوشراري',
    'زاوية الجديدي',
    'المعمورة',
    'عزمور',
    'حمام الغزاز',
    'تازركة',
    'الصمعة',
    'دار علوش',
    'قربص',
  ],
  سليانة: [
    'الجميع',
    'سليانة',
    'مكثر',
    'بوعرادة',
    'الكريب',
    'قعفور',
    'كسرى',
    'روحية',
    'سيدي مرشد',
    'بورويس',
    'بارجو',
    'حبابسة',
    'عروسة',
  ],
  صفاقس: [
    'الجميع',
    'صفاقس',
    'ساقية الزيت',
    'ساقية الدائر',
    'طينة',
    'العين',
    'عقارب',
    'قرمدة',
    'العوابد الخزانة',
    'الصخيرة',
    'محرس',
    'الحنشة',
    'بير علي بن خليفة',
    'جبنيانة',
    'العامرة',
    'الشيحية',
    'ناظور سيدي علي بالعابد',
    'منزل شاكر',
    'حاجب',
    'حزق اللوز ',
    'العشاش -بو جربوع-العواذنة-ماجل الدرج',
    'النصر',
    'الغريبة',
    'قرقنة',
  ],
  'سيدي بوزيد': [
    'الجميع',
    'سيدي بوزيد',
    'الرقاب',
    'منزل بوزيان',
    'لسودة',
    'مزونة',
    'بير الحفي',
    'جلمة',
    'الفايض بنور ',
    'مكناسي',
    'السعيدة',
    'سوق جديد',
    'سبالة ولاد عسكر ',
    'أولاد حفوز',
    'سيدي علي بن عون',
    'بطان غزالة',
    'رحال',
    'المنصورة',
  ],
  سوسة: [
    'الجميع',
    'سوسة',
    'مساكن',
    'الزهور',
    'القلعة الكبرى',
    'حمام سوسة',
    'قلعة صغيرة',
    'النفيضة',
    'أكودة',
    'بوفيشة',
    'جيريميت هيشر',
    'زاوية سوسة',
    'سيدي بو علي',
    'كوندار',
    'سيدي الهاني',
    'مسعدين',
    'الزّاوية القصيبة الثريات',
    'هرقلة',
    'شط مريم',
  ],
  تطاوين: ['الجميع', 'تطاوين', 'غمراسن', 'السمار', 'رمادة', 'بير لحمر', 'شينيني - الدويرت', 'ذهبية'],
  توزر: ['الجميع', 'توزر', 'نفطة', 'دقاش', 'حامة الجريد', 'تمغزة', 'حزوة'],
  زغوان: ['الجميع', 'زغوان', 'الفحص', 'الناظور', 'زريبة', 'بئر مشارقة', 'صواف', 'العمايم', 'جبل الوسط'],
};

/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All psychologist
export const fetchAllPsychologists = createAsyncThunk(
  'psychologist/fetchAllPsychologists',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/psychologists`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// fetch single psychologist object
export const fetchPsychologist = createAsyncThunk('psychologist/fetchPsychologist', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/psychologists/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create new psychologist
export const createPsychologist = createAsyncThunk(
  'psychologist/createPsychologist',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/v1/api/psychologist`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update blood Bank
export const updatePsychologist = createAsyncThunk(
  'psychologist/updatePsychologist',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/v1/api/psychologists/${data._id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Delete blood Bank
export const deletePsychologist = createAsyncThunk(
  'psychologist/deletePsychologist',
  async (_id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'delete',
        url: `${API_ENDPOINT}/v1/api/psychologists/${_id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Psychologist slice
const Psychologist = createSlice({
  name: 'Psychologist',
  initialState: {
    psychologists: [],
    psychologist: null,
    loading: false,
  },
  extraReducers: {
    [fetchAllPsychologists.fulfilled]: (state, action) => {
      state.psychologists = action.payload;
      state.loading = false;
    },
    [fetchAllPsychologists.pending]: (state) => {
      state.loading = true;
    },
    [fetchPsychologist.fulfilled]: (state, action) => {
      state.psychologist = action.payload;
      state.loading = false;
    },
    [fetchPsychologist.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default Psychologist.reducer;

// Selectors
export const selectAllPsychologists = (state) => state.Psychologist.psychologists;
export const selectPsychologist = (state) => state.Psychologist.psychologist;
export const selectPsychologistLoading = (state) => state.Psychologist.loading;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

// redux
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Layout, notification, Row } from 'antd';
import FormBuilder from 'antd-form-builder';

// reducers
import { SwapLeftOutlined } from '@ant-design/icons';
import { selectSessionLoading } from '../../../reducers/Session.slice';

// Local Images
import LOGO from '../../../assets/atgmo-logo.png';

// config
import { API_ENDPOINT } from '../../../common/config';

// style
import '../../../pages/LoginPage/index.css';

/* -------------------------------------------------------------------------- */
/*                                 Login Page                                 */
/* -------------------------------------------------------------------------- */
function ResetPasswordPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const isLoading = useSelector(selectSessionLoading);
  const [isDone, setIsDone] = useState(false);

  /* ---------------------------------- CONST --------------------------------- */
  const resetFormFields = {
    fields: [
      {
        key: 'newPassword',
        label: 'Mot de passe',
        widget: 'password',
        onChange: () => {
          if (form.isFieldTouched('verifyPassword')) {
            form.validateFields(['verifyPassword']);
          }
        },
        rules: [
          {
            required: true,
            message: 'Mot de passe obligatoire',
          },
        ],
      },
      {
        key: 'verifyPassword',
        label: 'Confirmez le',
        widget: 'password',
        rules: [
          {
            validator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (value !== form.getFieldValue('newPassword')) {
                  reject(new Error('Deux mots de passe sont incohérents.'));
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: 'Mot de passe obligatoire',
          },
        ],
      },
    ],
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const { token } = useParams();
  const onSubmit = (values) => {
    const data = {
      ...values,
      token,
    };

    window.localStorage.clear();
    axios
      .post(`${API_ENDPOINT}/v1/api/auth/user/password/reset/${data.token}`, values)
      .then(() => {
        notification.success({
          message: 'Utilisateur',
          description: 'Votre mot de passe a été réinitialisé avec succès',
        });
        setIsDone(true);
        setTimeout(() => {
          window.location.href = 'http://onelink.to/qmc2g5';
        }, 2000);
      })
      .catch((error) =>
        notification.error({
          message: 'Utilisateur',
          description: error.message,
        }),
      )
      .then(unwrapResult);
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Layout className="reset-password-page">
      <Layout.Content>
        <Link to="/home">
          <Button type="primary">
            <SwapLeftOutlined />
          </Button>
        </Link>
        <Row align="center" justify="center">
          <img src={LOGO} className="atgmo-logo" alt="AtGmo" />
        </Row>
        <Row align="center" justify="center">
          <Form name="reset-password-form" onFinish={onSubmit} form={form} className="login-page-layout">
            {isDone ? (
              <p className="form-message">
                Votre mot de passe a été réinitialisé avec succès, <br />
                vous pouvez maintenant vous connecter avec votre nouveau mot de passe.
              </p>
            ) : (
              <>
                <p className="form-message">Réinitialiser votre mot de passe</p>
                <FormBuilder form={form} meta={resetFormFields} />
                <Form.Item>
                  <Button htmlType="submit" type="primary" className="login-button" loading={isLoading}>
                    CONNEXION
                  </Button>
                </Form.Item>
              </>
            )}
          </Form>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

ResetPasswordPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default ResetPasswordPage;

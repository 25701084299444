/* eslint-disable mdx/no-unused-expressions */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { useDispatch } from 'react-redux';

// UI Components
import { Form, Button, Modal, notification, Upload, Row, Avatar, DatePicker, Col } from 'antd';
import { EditOutlined, InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import Dragger from 'antd/lib/upload/Dragger';

// reducers
import { fetchAllEvents } from '../../../reducers/Event.slice';

// helper && config
import { localMoment } from '../../../common/helpers';
import { API_ENDPOINT } from '../../../common/config';
import { dateFormat } from '../../../common/constants';

/* -------------------------------------------------------------------------- */
/*                                 Event Form                                 */
/* -------------------------------------------------------------------------- */
function EventForm({ onlyFormItems, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const [multipleFile, setMultipleFile] = useState(null);
  const [startAt, setStartAt] = useState(record?.start_at ? localMoment(record?.start_at) : localMoment());
  const [endAt, setEndAt] = useState(record?.endAt ? localMoment(record?.endAt) : localMoment());
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllEvents());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */

  /**
   *
   * @param {object} entry data entry from form
   */

  const onClickSubmit = (entry) => {
    const data = new FormData();
    const handleDataObject = () => {
      singleFile !== null && data.append('photo', singleFile);
      form.getFieldValue('isEvent') === true && data.append('end_at', endAt);
      if (entry.link) data.append('link', entry.link);
      data.append('start_at', startAt);
      data.append('event_name_ar', entry.event_name_ar);
      data.append('description_ar', entry.description_ar);
      data.append('event_location_ar', entry.event_location_ar);
      data.append('event_name_fr', entry.event_name_fr);
      data.append('description_fr', entry.description_fr);
      data.append('event_location_fr', entry.event_location_fr);
      data.append('latitude', entry.latitude);
      data.append('longitude', entry.longitude);
      data.append('isEvent', entry.isEvent);
    };

    if (multipleFile !== null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < multipleFile.length; i++) {
        data.append('gallery', multipleFile[i].originFileObj);
      }
      handleDataObject(data);
    } else {
      handleDataObject(data);
    }

    if (record) {
      axios
        .put(`${API_ENDPOINT}/v1/api/events/${record._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Actualité',
            description: 'Actualité mis à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllEvents());
        })
        .catch((error) =>
          notification.error({
            message: 'Actualité',
            description: error.message,
          }),
        );
      form.resetFields();
    } else {
      axios
        .post(`${API_ENDPOINT}/v1/api/event`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Actualité',
            description: 'L’événement a été créé avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllEvents());
        })
        .catch((error) =>
          notification.error({
            message: 'Actualité',
            description: error.message,
          }),
        );
      form.resetFields();
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */

  const GENERAL_INFO = {
    columns: 2,
    fields: [
      {
        key: 'isEvent',
        label: 'Type',
        placeholder: 'Type',
        widget: 'switch',
        colSpan: 2,
        initialValue: record?.isEvent ?? false,
        extra: form.getFieldValue('isEvent') === true ? 'Événement' : 'Actualité',
      },
      {
        key: 'event_name_fr',
        label: form.getFieldValue('isEvent') === true ? "Titre de l'événement" : "Titre de l'actualité",
        placeholder: form.getFieldValue('isEvent') === true ? "Titre de l'événement" : "Titre de l'actualité",
        initialValue: record?.event_name_fr,
        rules: [
          {
            required: true,
            message: 'Titre est requis',
          },
        ],
      },
      {
        key: 'event_name_ar',
        label: 'اسم الحدث',
        placeholder: 'اسم الحدث',
        initialValue: record?.event_name_ar,
        rules: [
          {
            required: true,
            message: 'اسم الحدث مطلوب',
          },
        ],
      },
      {
        key: 'description_fr',
        label: 'Description',
        placeholder: 'Description',
        initialValue: record?.description_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'La description est requis',
          },
        ],
      },
      {
        key: 'description_ar',
        label: 'الوصف',
        placeholder: 'الوصف',
        initialValue: record?.description_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'الوصف مطلوب',
          },
        ],
      },
    ],
  };
  const CUSTOM_INFO = {
    columns: 2,
    fields: [
      {
        key: 'event_location_fr',
        label: 'Lieu',
        placeholder: 'Lieu',
        initialValue: record?.event_location_fr ?? '',
        rules: [
          {
            required: form.getFieldValue('isEvent') === true,
            message: 'Lieu est requis',
          },
        ],
      },
      {
        key: 'event_location_ar',
        label: 'المكان',
        placeholder: 'المكان',
        initialValue: record?.event_location_ar ?? '',
        rules: [
          {
            required: form.getFieldValue('isEvent') === true,
            message: 'المكان مطلوب',
          },
        ],
      },
      {
        key: 'latitude',
        placeholder: 'Latitude',
        label: 'Latitude',
        initialValue: record?.latitude ?? '0',
      },
      {
        key: 'longitude',
        placeholder: 'Longitude',
        label: 'Longitude',
        initialValue: record?.longitude ?? '0',
      },
      {
        key: 'link',
        placeholder: 'Lien',
        label: 'Lien',
        initialValue: record?.link ?? '',
        colSpan: 2,
        rules: [
          {
            type: 'url',
            message: 'Ce champ doit être une URL valide.',
          },
        ],
        extra: 'Exemple pour URL valide http://www.example.com',
      },
    ],
  };
  // photo
  const uploadSingleFile = (data) => {
    setSingleFile(data.fileList[0].originFileObj);
  };
  // Gallery
  const uplodMultipleFiles = (data) => {
    setMultipleFile(data.fileList);
  };
  // start date
  const onChangeStartDate = (value) => {
    setStartAt(value);
  };
  // end date
  const onChangeEndDate = (value) => {
    setEndAt(value);
  };
  // disable date before start date
  const disabledDate = (date) => {
    return date < startAt;
  };

  const props = {
    beforeUpload: () => {
      return false;
    },
  };

  // force update form
  const forceUpdate = FormBuilder.useForceUpdate();
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="event-form">
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form layout="horizontal" onFinish={(values) => onClickSubmit(values)} onValuesChange={forceUpdate} form={form}>
          <Row align="middle" justify="center">
            {record?.photo && <Avatar size={120} src={`${API_ENDPOINT}/${record.photo}`} />}
          </Row>
          <Row align="middle" justify="center">
            <Form.Item valuePropName="photo">
              <Upload onChange={uploadSingleFile} {...props} accept="image/*" maxCount={1}>
                <Button icon={<UploadOutlined />}>
                  {record
                    ? "Cliquez pour changer l'image principale de bannière"
                    : "Cliquez pour télécharger l'image principale de bannière"}
                </Button>
              </Upload>
            </Form.Item>
          </Row>
          <Form.Item valuePropName="gallery">
            <Dragger {...props} onChange={uplodMultipleFiles} accept="image/*" maxCount={4} multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Cliquez ou faites glisser le fichier dans cette zone pour le télécharger galerie d&apos;image
              </p>
              <p className="ant-upload-hint">4 images maximum</p>
            </Dragger>
          </Form.Item>
          <Row align="middle" justify="center">
            <Avatar.Group maxCount={5}>
              {record?.gallery &&
                record?.gallery?.map((photo, i) => <Avatar key={i} src={`${API_ENDPOINT}/${photo}`} />)}
            </Avatar.Group>
          </Row>
          <FormBuilder form={form} meta={GENERAL_INFO} />
          <Row justify="space-around" align="middle">
            <Col>
              <Form.Item
                valuePropName="start_at"
                label={form.getFieldValue('isEvent') === true ? 'Date de début' : 'Date'}
              >
                <DatePicker
                  showTime
                  format={dateFormat}
                  defaultValue={localMoment(record?.start_at)}
                  onChange={onChangeStartDate}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('isEvent') === true && (
              <Col>
                <Form.Item valuePropName="end_at" label="Date de fin">
                  <DatePicker
                    showTime
                    format={dateFormat}
                    disabledDate={disabledDate}
                    defaultValue={localMoment(record?.end_at)}
                    onChange={onChangeEndDate}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <FormBuilder form={form} meta={CUSTOM_INFO} />

          <Row align="middle" justify="center">
            {!onlyFormItems && (
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

EventForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

EventForm.defaultProps = {
  isCreatedForm: false,
};

export default EventForm;

/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All donation
export const fetchAllDonnations = createAsyncThunk('donnation/fetchAllDonnations', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/donnations`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch fetch Donnation By Help Request Id
export const fetchDonnationByHelpRequestId = createAsyncThunk(
  'donnation/fetchDonnationByHelpRequestId',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/donnation/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update Donnation
export const updateDonnation = createAsyncThunk('donnation/updateDonnation', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/v1/api/donnation/${data._id}/action`,
      data: data.fields,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Donnation slice
const Donnation = createSlice({
  name: 'Donnation',
  initialState: {
    allDonnations: [],
    donnationHR: [],
    loading: false,
  },
  extraReducers: {
    [fetchAllDonnations.fulfilled]: (state, action) => {
      state.allDonnations = action.payload;
      state.loading = false;
    },
    [fetchAllDonnations.pending]: (state) => {
      state.loading = true;
    },
    [fetchDonnationByHelpRequestId.fulfilled]: (state, action) => {
      state.donnationHR = action.payload;
      state.loading = false;
    },
    [fetchDonnationByHelpRequestId.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default Donnation.reducer;

// Selectors
export const selectAllDonnations = (state) => state.Donnation.allDonnations;
export const selectDonnationHR = (state) => state.Donnation.donnationHR;
export const selectQALoading = (state) => state.Donnation.loading;

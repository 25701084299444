/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All question and answer
export const fetchAllDiseases = createAsyncThunk('disease/fetchAllDiseases', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/blood/diseases`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// fetch single question and answer object
export const fetchDisease = createAsyncThunk('disease/fetchDisease', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/blood/diseases/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create new question and answer
export const createDisease = createAsyncThunk('disease/createDisease', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'post',
      url: `${API_ENDPOINT}/v1/api/blood/disease`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Update questionAndAnswer
export const updateDisease = createAsyncThunk('disease/updateDisease', async (data, { rejectWithValue }) => {
  try {
    const config = {
      method: 'put',
      url: `${API_ENDPOINT}/v1/api/blood/diseases/${data._id}`,
      data: data.fields,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Delete questionAndAnswer
export const deleteDisease = createAsyncThunk('disease/deleteDisease', async (_id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/v1/api/blood/diseases/${_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Disease slice
const Disease = createSlice({
  name: 'Disease',
  initialState: {
    diseases: [],
    disease: null,
    loading: false,
  },
  extraReducers: {
    [fetchAllDiseases.fulfilled]: (state, action) => {
      state.diseases = action.payload;
      state.loading = false;
    },
    [fetchAllDiseases.pending]: (state) => {
      state.loading = true;
    },
    [fetchDisease.fulfilled]: (state, action) => {
      state.disease = action.payload;
      state.loading = false;
    },
    [fetchDisease.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default Disease.reducer;

// Selectors
export const selectAllDiseases = (state) => state.Disease.diseases;
export const selectDisease = (state) => state.Disease.disease;

/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

/**
 * Helper for the redundant initial state
 */
const getInitialState = () => ({ helpRequests: [], helpRequest: null, loading: false });

// fetch All help request
export const fetchAllHelpRequests = createAsyncThunk(
  'helpRequest/fetchAllHelpRequests',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/help-requests`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// fetch single help request object
export const fetchHelpRequest = createAsyncThunk('helpRequest/fetchHelpRequest', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/help-requests/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create new help request
export const createHelpRequest = createAsyncThunk(
  'helpRequest/createHelpRequest',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/v1/api/help-request`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data,
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update help request
export const updateHelpRequest = createAsyncThunk(
  'helpRequest/updateHelpRequest',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/v1/api/help-requests/${data._id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Delete help request
export const deleteBloodbank = createAsyncThunk('helpRequest/deleteBloodbank', async (_id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/v1/api/help-requests/${_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// HelpRequest slice
const HelpRequest = createSlice({
  name: 'HelpRequest',
  initialState: getInitialState(),
  extraReducers: {
    [fetchAllHelpRequests.fulfilled]: (state, action) => {
      const stateUpdate = { ...state };
      stateUpdate.helpRequests = action.payload;
      stateUpdate.loading = false;
      return stateUpdate;
    },
    [fetchAllHelpRequests.pending]: (state) => {
      const stateUpdate = { ...state };
      stateUpdate.loading = true;
      return stateUpdate;
    },
    [fetchAllHelpRequests.rejected]: (state) => {
      const stateUpdate = { ...state };
      stateUpdate.loading = false;
      return stateUpdate;
    },
    [fetchHelpRequest.fulfilled]: (state, action) => {
      const stateUpdate = { ...state };
      stateUpdate.helpRequest = action.payload;
      stateUpdate.loading = false;
      return stateUpdate;
    },
    [fetchHelpRequest.pending]: (state) => {
      const stateUpdate = { ...state };
      stateUpdate.loading = true;
      return stateUpdate;
    },
    [fetchHelpRequest.rejected]: (state) => {
      const stateUpdate = { ...state };
      stateUpdate.loading = false;
      return stateUpdate;
    },
  },
});

export default HelpRequest.reducer;

// Selectors
export const selectAllHelpRequests = (state) => state.HelpRequest.helpRequests;
export const selectHelpRequest = (state) => state.HelpRequest.helpRequest;
export const selectHR = (state) => state.HelpRequest;
export const selectHelpRequestLoading = (state) => state.HelpRequest.loading;

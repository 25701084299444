/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// UI lib components
import { Layout, Menu } from 'antd';

// Local Images
import HOME from '../assets/icons/home-icon.svg';
import ATGMO from '../assets/icons/atgmo-icon.svg';
import GIFT from '../assets/icons/don-de-vie-icon.svg';
import PARRAIGNAGE from '../assets/icons/parraignage-icon.svg';
import USERS from '../assets/icons/users-icon.svg';

// Style
import './index.css';

/* -------------------------------------------------------------------------- */
/*                                  Side Menu                                 */
/* -------------------------------------------------------------------------- */
function SideMenu() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Sider } = Layout;
  const location = useLocation();
  const history = useHistory();

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  })

  /* ---------------------------------- CONST --------------------------------- */
  const routes = [
    {
      key: '1',
      path: '/home',
      name: 'Home',
      icon: <img src={HOME} alt="home" />,
    },
    {
      key: '2',
      path: '/gift',
      name: 'Don de vie',
      icon: <img src={GIFT} alt="home" />,
    },
    {
      key: '3',
      path: '/sponsorship',
      name: 'Parrainage',
      icon: <img src={PARRAIGNAGE} alt="home" />,
    },
    {
      key: '4',
      path: '/atgmo',
      name: 'atGmo',
      icon: <img src={ATGMO} alt="home" />,
    },
    {
      key: '5',
      path: '/users',
      name: 'utilisateurs',
      icon: <img src={USERS} alt="home" />,
    },
  ];

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const [selectedKey, setSelectedKey] = useState(
    routes?.find((_item) => location.pathname.startsWith(_item.path))?.key,
  );

  const onClickMenu = (item) => {
    const clicked = routes?.find((_item) => _item.key === item.key);
    history.push(clicked.path);
  };

  useEffect(() => {
    setSelectedKey(routes.find((_item) => location.pathname.startsWith(_item.path))?.key);
  }, [location]);



  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])


  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <>
      {
        windowDimenion.winWidth > '850' &&
        <Sider id="sider-menu">
          <Menu
            defaultSelectedKeys={['0']}
            style={selectedKey ? { color: 'red' } : { color: 'blue' }}
            selectedKeys={[selectedKey]}
            mode="inline"
            onClick={onClickMenu}
          >
            {routes?.map((item) => (
              <Menu.Item icon={item.icon} key={item.key}>
                <Link to={item.path}>{item.name}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      }
    </>
  );
}

export default SideMenu;

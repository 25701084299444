/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Avatar, Empty } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { deleteDisease, fetchAllDiseases, selectAllDiseases } from '../../reducers/disease.slice';

// local components
import DiseaseForm from './DiseaseForm';

// helpers
import { RetriveImage } from '../../common/helpers';

/* -------------------------------------------------------------------------- */
/*                                Disease Page                                */
/* -------------------------------------------------------------------------- */
function DiseaseComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  // Selectors
  const DiseaseObject = useSelector(selectAllDiseases);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllDiseases());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeDisease = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${data?.title_fr}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteDisease(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Supprimer la maladie',
              description: 'La maladie a été supprimée avec succès',
            });
            dispatch(fetchAllDiseases());
          })
          .catch((err) =>
            notification.error({
              message: 'Supprimer la maladie',
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const DISEASE = DiseaseObject && DiseaseObject.diseases;

  const DISEASE_DATA = DISEASE?.map((disease, index) => ({
    // in common
    key: index,
    _id: disease._id,
    photo: disease.photo,
    // for arab lang
    title_ar: disease.ar.title,
    description_ar: disease.ar.description,
    // for french lang
    title_fr: disease.fr.title,
    description_fr: disease.fr.description,
  }));

  const DISEASE_COLUMN = [
    {
      title: 'Image',
      key: 'photo',
      dataIndex: 'photo',
      render: (photo) => <Avatar size={64} src={RetriveImage(photo)} />,
    },
    {
      title: 'Nom de la maladie',
      key: 'title_fr',
      dataIndex: 'title_fr',
    },
    {
      title: 'Description de la maladie',
      key: 'description_fr',
      dataIndex: 'description_fr',
      render: (description) => v.truncate(description, 20),
    },
    {
      title: 'وصف المرض ',
      key: 'description_ar',
      dataIndex: 'description_ar',
      render: (description) => v.truncate(description, 20),
    },
    {
      title: 'اسم المرض',
      key: 'title_ar',
      dataIndex: 'title_ar',
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <DiseaseForm record={record} />
          </Col>
          <Col className="mr">
            <Button onClick={() => removeDisease(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
      fixed: 'right',
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {DISEASE?.length === 0 ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 250,
          }}
          description="aucune maladie trouvée"
        >
          <DiseaseForm label="Créer une nouvelle maladie" isCreatedForm />
        </Empty>
      ) : (
        <>
          <DiseaseForm label="Créer une nouvelle maladie" isCreatedForm />
          <Table columns={DISEASE_COLUMN} dataSource={DISEASE_DATA} scroll={{ x: 1400 }} />
        </>
      )}
    </div>
  );
}

export default DiseaseComponent;

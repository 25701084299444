/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification, Row } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import { createPsychologist, fetchAllPsychologists, updatePsychologist } from '../../../reducers/Psychologist.slice';

/* -------------------------------------------------------------------------- */
/*                               Blood Bank Form                              */
/* -------------------------------------------------------------------------- */
function PsychologistForm({ onChange, onlyFormItems, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPsychologists());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const [form] = Form.useForm();

  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    if (record) {
      dispatch(
        updatePsychologist({
          _id: record._id,
          fields: {
            ...entry,
          },
        }),
      )
        .then(() => {
          notification.success({
            message: 'Psychologue',
            description: 'Psychologue Mis à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllPsychologists());
        })
        .catch((error) =>
          notification.error({
            message: 'Psychologue',
            description: error.message,
          }),
        )
        .then(unwrapResult);
      form.resetFields();
    } else {
      dispatch(
        createPsychologist({
          ...entry,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Psychologue',
            description: 'Psychologue a été créées avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllPsychologists());
        })
        .catch((error) =>
          notification.error({
            message: 'Psychologue',
            description: error.message,
          }),
        );
      form.resetFields();
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const PSYCHOLOGIST_FILED = {
    columns: 2,
    fields: [
      {
        key: 'fullName_fr',
        placeholder: 'Nom et Prénom',
        label: 'Nom et Prénom',
        initialValue: record?.fullName_fr,
        rules: [
          {
            required: true,
            message: 'Le nom et prénom est obligatoire',
          },
        ],
      },
      {
        key: 'fullName_ar',
        placeholder: 'الإسم واللقب',
        label: 'الإسم واللقب',
        initialValue: record?.fullName_ar,
        rules: [
          {
            required: true,
            message: ' الإسم واللقب مطلوب',
          },
        ],
      },
      {
        key: 'speciality_fr',
        placeholder: 'spécialité',
        label: 'spécialité',
        initialValue: record?.speciality_fr,
        widget: 'textarea',
      },
      {
        key: 'speciality_ar',
        placeholder: 'التخصص',
        label: 'التخصص',
        initialValue: record?.speciality_ar,
        widget: 'textarea',
      },
      {
        key: 'address_fr',
        placeholder: 'Adresse',
        label: 'Adresse',
        initialValue: record?.address_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: "L'adresse est obligatoire",
          },
        ],
      },
      {
        key: 'address_ar',
        placeholder: 'العنوان',
        label: 'العنوان',
        initialValue: record?.address_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'العنوان مطلوب',
          },
        ],
      },
      {
        key: 'latitude',
        placeholder: 'Latitude',
        label: 'Latitude',
        initialValue: record?.latitude ?? '0',
      },
      {
        key: 'longitude',
        placeholder: 'Longitude',
        label: 'Longitude',
        initialValue: record?.longitude ?? '0',
      },
      {
        key: 'email',
        label: 'Adresse e-mail',
        placeholder: 'Adresse e-mail',
        initialValue: record?.email,
        rules: [
          {
            type: 'email',
            message: "L'entrée n'est pas valide E-mail!",
          },
        ],
      },
      {
        key: 'link',
        placeholder: 'Lien',
        label: 'Lien',
        initialValue: record?.link ?? '',
        rules: [
          {
            type: 'url',
            message: 'Ce champ doit être une URL valide.',
          },
        ],
        extra: 'Exemple pour URL valide http://www.example.com',
      },
      {
        key: 'phoneNumber',
        placeholder: 'Numéro de téléphone',
        label: 'Numéro de téléphone',
        initialValue: record?.phoneNumber ?? '',
        colSpan: 2,
        extra: "Si vous avez plus d'un numéro, mettez après chaque numéro un '-' exemple +216........- +216........",
      },
      {
        key: 'faxNumber',
        placeholder: 'Numéro de fax',
        label: 'Numéro de fax',
        initialValue: record?.faxNumber ?? '',
        colSpan: 2,
        extra: "Si vous avez plus d'un numéro, mettez après chaque numéro un '-' exemple +216........- +216........",
      },
    ],
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="blood-bank-form">
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={(values) => onClickSubmit(values)}
          onValuesChange={onChange}
          form={form}
          encType="multipart/form-data"
        >
          <FormBuilder form={form} meta={PSYCHOLOGIST_FILED} />

          <Row align="middle" justify="center">
            {!onlyFormItems && (
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

PsychologistForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

PsychologistForm.defaultProps = {
  isCreatedForm: false,
};

export default PsychologistForm;

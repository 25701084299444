/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI Components
import { Tabs } from 'antd';

// Local Components
import BloodBankComponent from '../../components/BloodBankComponent';
import BloodHelpRequestComponent from '../../components/BloodHelpRequestComponent';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function Gift() {
  /* ---------------------------------- CONST --------------------------------- */
  const { TabPane } = Tabs;

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Tabs defaultActiveKey="1" size="large" style={{ marginBottom: 32 }}>
      <TabPane tab="Liste des demandes du sang" key="1">
        <BloodHelpRequestComponent />
      </TabPane>

      <TabPane tab="Banque du sang" key="2">
        <BloodBankComponent />
      </TabPane>
    </Tabs>
  );
}

export default Gift;

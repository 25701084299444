/* eslint-disable no-shadow */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Avatar, Empty, Tag, Input, Space, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// reducers
import { deleteEvent, fetchAllEvents, selectAllEvents } from '../../reducers/Event.slice';

// Local Components
import EventForm from './EventForm';

// Local images
import EMPTY_IMAGE from '../../assets/empty.png';

// helpers
import { localMoment, RetriveImage } from '../../common/helpers';
import { API_ENDPOINT } from '../../common/config';
import { dateFormat } from '../../common/constants';

/* -------------------------------------------------------------------------- */
/*                                 Event Page                                 */
/* -------------------------------------------------------------------------- */
function EventComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);
  const { confirm } = Modal;
  // Selectors
  const AllEvents = useSelector(selectAllEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllEvents());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeEvent = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${v.truncate(data?.event_name_fr, 50)}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteEvent(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: "Supprimer l'événement",
              description: "L'événement a été supprimée avec succès",
            });
            dispatch(fetchAllEvents());
          })
          .catch((err) =>
            notification.error({
              message: "Supprimer l'événement",
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const events = AllEvents && AllEvents?.events;

  const eventsData = events?.map((event, index) => ({
    // in common
    key: index,
    _id: event?._id,
    gallery: event?.gallery,
    photo: event?.photo,
    start_at: event?.start_at,
    end_at: event?.end_at,
    link: event?.link,
    isEvent: event?.isEvent,
    latitude: event.coordinates?.latitude,
    longitude: event.coordinates?.longitude,
    // for arabic language
    event_name_ar: event.ar?.event_name,
    description_ar: event.ar?.description,
    event_location_ar: event.ar?.event_location,
    // for french language
    event_name_fr: event.fr?.event_name,
    description_fr: event.fr?.description,
    event_location_fr: event.fr?.event_location,
  }));

  /* -------------------------------------------------------------------------- */
  /*                          SEARCH && FILTER HELPERES                         */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const EVENTS_COLUMN = [
    {
      title: 'Galerie',
      key: 'gallery',
      dataIndex: 'gallery',
      fixed: 'left',
      render: (gallery) => (
        <>
          {gallery.length !== 0 ? (
            <Avatar.Group maxCount={5}>
              {gallery?.map((photo, i) => (
                <Avatar size={42} key={i} src={`${API_ENDPOINT}/${photo}`} />
              ))}
            </Avatar.Group>
          ) : (
            <Avatar size={42} src={EMPTY_IMAGE} alt="no gallery" />
          )}
        </>
      ),
    },
    {
      title: 'Bannière',
      key: 'photo',
      dataIndex: 'photo',
      render: (photo) => <Avatar size={42} src={RetriveImage(photo)} />,
    },
    {
      title: 'Titre',
      key: 'event_name_fr',
      dataIndex: 'event_name_fr',
      ...getColumnSearchProps('event_name_fr'),
      render: (title) => v.truncate(title, 50),
    },
    {
      title: 'Description',
      key: 'description_fr',
      dataIndex: 'description_fr',
      render: (description) => v.truncate(description, 100),
    },
    {
      title: 'Type',
      key: 'isEvent',
      dataIndex: 'isEvent',
      render: (type) => (type === true ? <Tag color="blue">Événement</Tag> : <Tag color="orange">Actualité</Tag>),
    },
    {
      title: 'Lieu',
      key: 'event_location_fr',
      dataIndex: 'event_location_fr',
      render: (Lieu) => v.truncate(Lieu, 50),
    },
    {
      title: 'Start At',
      key: 'start_at',
      dataIndex: 'start_at',
      render: (startDate) => localMoment(startDate).format(dateFormat),
    },
    {
      title: 'End At',
      key: 'end_at',
      dataIndex: 'end_at',
      render: (endDate) => endDate !== undefined && localMoment(endDate).format(dateFormat),
    },
    {
      title: 'Link',
      key: 'link',
      dataIndex: 'link',
      render: (link) => (
        <Link target="_blank" to={{ pathname: `${link}` }}>
          {link}
        </Link>
      ),
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <EventForm record={record} />
          </Col>
          <Col className="mr">
            <Button onClick={() => removeEvent(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {events?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="Aucune actualité trouvée"
            >
              <EventForm label="Créer une nouvelle actualité" isCreatedForm />
            </Empty>
          ) : (
            <>
              <EventForm label="Créer une nouvelle actualité" isCreatedForm />
              <Table columns={EVENTS_COLUMN} dataSource={eventsData} scroll={{ x: 1400 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default EventComponent;

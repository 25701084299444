/* eslint-disable no-shadow */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Tag, Avatar, Empty, Input, Space } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// reducers
import { fetchAllUsers, selectAllUsers, deleteUser } from '../../reducers/User.slice';
import { selectSessionUser } from '../../reducers/Session.slice';

// Local Components
import UserForm from './UserForm';
import Loader from '../../shared/Components/Loader';

// helpers
import { RetriveUserAvatar } from '../../common/helpers';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function UserList() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);
  // Selectors
  const users = useSelector(selectAllUsers);
  const currentUser = useSelector(selectSessionUser);
  const dispatch = useDispatch();

  const allUsers = users?.user?.filter((user) => !user.is_admin && !user.is_manager);

  useEffect(() => {
    dispatch(fetchAllUsers());
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeUser = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer l'utilisateur < ${data?.fullName} > ?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteUser(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: "Supprimer l'utilisateur",
              description: "L'utilisateur a été supprimé avec succès",
            });
            dispatch(fetchAllUsers());
          })
          .catch((err) =>
            notification.error({
              message: "Supprimer l'utilisateur",
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                          SEARCH && FILTER HELPERES                         */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  /* -------------------------------- CONSTANTS ------------------------------- */

  // show only user with no admin or manager role
  const usersData = allUsers?.map((user, index) => ({
    key: index,
    _id: user?._id,
    photo: user?.photo,
    fullName: user?.fullName,
    email: user?.email,
    governorate: user?.governorate,
    municipality: user?.municipality,
    is_active: user?.is_active,
    phoneNumber: user?.phoneNumber,
    whatsappNumber: user?.whatsappNumber,
    // bloodType: user?.bloodType,
    age: user?.age,
    gender: user?.gender,
    dateOfBirth: user?.dateOfBirth,
    role: Object.keys(user).filter((k) => user[k] === true),
  }));

  const USER_COLUMN = [
    {
      title: 'Avatar',
      key: 'photo',
      dataIndex: 'photo',
      render: (_i, user) => <Avatar size={64} src={RetriveUserAvatar(user)} />,
    },
    {
      title: 'Nom et prénom',
      key: 'fullName',
      dataIndex: 'fullName',
      ...getColumnSearchProps('fullName'),
      fixed: 'left',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Téléphone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
    },
    // {
    //   title: 'Groupe Sanguin',
    //   key: 'bloodType',
    //   dataIndex: 'bloodType',
    // },
    {
      title: 'age',
      key: 'age',
      dataIndex: 'age',
    },
    {
      title: 'gender',
      key: 'gender',
      dataIndex: 'gender',
    },
    {
      title: 'Whatsapp',
      key: 'whatsappNumber',
      dataIndex: 'whatsappNumber',
    },
    {
      title: 'Gouvernorat',
      key: 'governorate',
      dataIndex: 'governorate',
    },
    {
      title: 'Municipalité',
      key: 'municipality',
      dataIndex: 'municipality',
    },
    {
      title: 'Rôle',
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <>
          {role.map((tag) => {
            let color = tag === 'is_volunteer' ? 'orange' : 'blue';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <>
                {tag !== 'is_active' && (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                )}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: 'Compte',
      key: 'is_active',
      dataIndex: 'is_active',
      render: (active) => (active === true ? 'Compte activé' : 'Compte désactivé '),
    },
    {
      render: (record) => (
        <Row>
          <Col>
            <UserForm record={record} />
          </Col>
          <Col className="mr">
            <Button onClick={() => removeUser(record)} danger disabled={currentUser?.is_manager}>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {allUsers?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucun utilisateur n'a été trouvé"
            >
              {/* <UserForm label="créer un nouvel utilisateur" isCreatedForm /> */}
            </Empty>
          ) : (
            <Table columns={USER_COLUMN} dataSource={usersData} scroll={{ x: 2100 }} />
          )}
        </>
      )}
    </div>
  );
}

export default UserList;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Empty, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { deleteBloodbank, fetchAllBloodBanks, selectAllBloodBanks } from '../../reducers/BloodBank.slice';

// local components
import BloodBankForm from './BloodBankForm';

/* -------------------------------------------------------------------------- */
/*                            Blood Bank Component                            */
/* -------------------------------------------------------------------------- */
function BloodBankComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  // Selectors
  const BloodBankObject = useSelector(selectAllBloodBanks);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBloodBanks());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeBloodBank = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${v.truncate(data?.title_fr, 50)}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteBloodbank(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Supprimer la banque du sang',
              description: 'La banque du sang a été supprimées avec succès',
            });
            dispatch(fetchAllBloodBanks());
          })
          .catch((err) =>
            notification.error({
              message: 'Supprimer la banque du sang',
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const BLOOD_BANK = BloodBankObject && BloodBankObject.bloodBanks;

  const BLOOD_BANK_DATA = BLOOD_BANK?.map((bank, index) => ({
    // in common
    key: index,
    _id: bank._id,
    phoneNumber: bank.phoneNumber,
    faxNumber: bank.faxNumber,
    latitude: bank.coordinates?.latitude,
    longitude: bank.coordinates?.longitude,
    website: bank.website,
    // for arab lang
    title_ar: bank.ar.title,
    address_ar: bank.ar.address,
    // for french lang
    title_fr: bank.fr.title,
    address_fr: bank.fr.address,
  }));

  const BLOOD_BANK_COLUMN = [
    {
      title: 'Nom',
      key: 'title_fr',
      dataIndex: 'title_fr',
      render: (title) => v.truncate(title, 100),
    },
    {
      title: 'Adresse',
      key: 'address_fr',
      dataIndex: 'address_fr',
      render: (address) => v.truncate(address, 70),
    },
    {
      title: 'Numéro de téléphone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Numéro de fax',
      key: 'faxNumber',
      dataIndex: 'faxNumber',
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <BloodBankForm record={record} />
          </Col>
          <Col className="mr">
            <Button type="danger" onClick={() => removeBloodBank(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {BLOOD_BANK_DATA?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucune banque du sang trouvée"
            >
              <BloodBankForm label="Créer une nouvelle banque du sang" isCreatedForm />
            </Empty>
          ) : (
            <>
              <BloodBankForm label="Créer une nouvelle banque du sang" isCreatedForm />
              <Table columns={BLOOD_BANK_COLUMN} dataSource={BLOOD_BANK_DATA} scroll={{ x: 1400 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default BloodBankComponent;

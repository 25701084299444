import { combineReducers } from 'redux';

import Session from '../reducers/Session.slice';
import Event from '../reducers/Event.slice';
import User from '../reducers/User.slice';
import Association from '../reducers/Association.slice';
import QuestionAndAnswer from '../reducers/questionAndAnswer.slice';
import Disease from '../reducers/disease.slice';
import BloodBank from '../reducers/BloodBank.slice';
import HelpRequest from '../reducers/HelpRequest.slice';
import Donnation from '../reducers/Donnation.slice';
import Psychologist from '../reducers/Psychologist.slice';

export default combineReducers({
  Session,
  Event,
  User,
  Association,
  Disease,
  QuestionAndAnswer,
  BloodBank,
  HelpRequest,
  Donnation,
  Psychologist,
});

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// Local Components
import MaterialHelpRequestComponent from '../../components/MaterialHelpRequestComponent';

/* -------------------------------------------------------------------------- */
/*                                  User Page                                 */
/* -------------------------------------------------------------------------- */
function Sponsorship() {
  /* -------------------------------- RENDERING ------------------------------- */
  return <MaterialHelpRequestComponent />;
}

export default Sponsorship;

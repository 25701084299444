/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// UI lib components
import { Row, Col, Layout, Divider, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { selectSessionUser, $logout } from '../reducers/Session.slice';

// Style
import './index.css';

/* -------------------------------------------------------------------------- */
/*                                   Top Bar                                  */
/* -------------------------------------------------------------------------- */
function TopBar() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { Header } = Layout;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectSessionUser);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
  })



  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Header id="top-bar" className="header">
      <Row align="middle" justify="space-between">
        <Col>
          <Link className="top-bar-link" to="/">
            Tnajjem
          </Link>
          <Divider type="vertical" />
        </Col>
        <Col>
          <Row align="middle" justify="space-around">
            <Col>
              {user && windowDimenion.winWidth > '800' && (
                <Row align="middle" justify="space-between">
                  <h1 className="user-name">{user.email}</h1>
                </Row>
              )}
            </Col>
            <Col>
              <Button
                className="top-bar-button"
                icon={<LogoutOutlined />}
                onClick={() => {
                  dispatch($logout());
                  history.push('/login');
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
}

export default TopBar;

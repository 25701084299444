/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

// redux
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Layout, notification, Row } from 'antd';

// reducers
import { selectSessionLoading } from '../../../reducers/Session.slice';

// config
import { API_ENDPOINT } from '../../../common/config';

// Local Images
import LOGO from '../../../assets/atgmo-logo.png';

// style
import '../../../pages/LoginPage/index.css';

/* -------------------------------------------------------------------------- */
/*                                 Login Page                                 */
/* -------------------------------------------------------------------------- */
function ActivateAccountPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const isLoading = useSelector(selectSessionLoading);
  const [isActive, setIsActive] = useState(false);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const { token } = useParams();
  const onSubmit = () => {
    // clear all localstorage
    window.localStorage.clear();
    axios
      .post(`${API_ENDPOINT}/v1/api/account/enable/${token}`, token)
      .then(() => {
        notification.success({
          message: 'Utilisateur',
          description: 'Votre compte a été activé avec succès',
        });
        setIsActive(true);
        setTimeout(() => {
          window.location.href = 'http://onelink.to/qmc2g5';
        }, 2000);
      })
      .catch((error) =>
        notification.error({
          message: 'Utilisateur',
          description: error.message,
        }),
      )
      .then(unwrapResult);
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Layout className="reset-password-page">
      <Layout.Content>
        <Row align="center" justify="center">
          <img src={LOGO} className="atgmo-logo" alt="AtGmo" />
        </Row>
        <Row align="center" justify="center">
          <Form name="reset-password-form" onFinish={onSubmit} form={form} className="login-page-layout">
            <Form.Item>
              {isActive ? (
                <p>Votre compte a été activé avec succès</p>
              ) : (
                <Button htmlType="submit" type="primary" className="login-button" loading={isLoading}>
                  Activer votre compte
                </Button>
              )}
            </Form.Item>
          </Form>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

ActivateAccountPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default ActivateAccountPage;

/* eslint-disable camelcase */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// route components
import App from './App';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import Home from './pages/Home';
import Gift from './pages/Gift';
import Sponsorship from './pages/Sponsorship';
import Atgmo from './pages/Atgmo';
import HelpRequestPageDetails from './shared/Pages/HelpRequestPageDetails';
import UserPage from './pages/UserPage';
import ResetPasswordPage from './shared/Pages/ResetPasswordPage';
import ForgotPasswordPage from './shared/Pages/ForgotPasswordPage';
import ActivateAccountPage from './shared/Pages/ActivateAccountPage';

/* ---------------------------------- CONST --------------------------------- */
// eslint-disable-next-line consistent-return
const getSessionToken = () => {
  if (localStorage.getItem('access_token')) {
    const JWT_TOKEN_DECODED = jwt_decode(localStorage.getItem('access_token'));
    if (JWT_TOKEN_DECODED.exp * 1000 < Date.now()) {
      localStorage.clear();
    }
    return localStorage.getItem('access_token');
  }
};

/* -------------------------------------------------------------------------- */
/*                                Layout Route                                */
/* -------------------------------------------------------------------------- */
// eslint-disable-next-line react/prop-types
function LayoutRoute({ component: Component, layout: Layout, ...rest }) {
  /* ---------------------------------- CONST --------------------------------- */
  const AuthRoute = (props) => {
    if (!getSessionToken()) {
      return <Redirect to="/login" />;
    }
    return <Route {...props} />;
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <AuthRoute
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

/* -------------------------------------------------------------------------- */
/*                                Render Routes                               */
/* -------------------------------------------------------------------------- */
export const renderRoutes = () => (
  /* -------------------------------- RENDERING ------------------------------- */
  <Router>
    <Switch>
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/reset-password/:token" component={ResetPasswordPage} />
      <Route path="/account/:token/enable" component={ActivateAccountPage} />
      <Route
        exact
        path="/login"
        render={() =>
          getSessionToken() && getSessionToken() !== null ? <Redirect exact from="/login" to="/" /> : <LoginPage />
        }
      />

      <LayoutRoute exact path="/home" component={Home} layout={App} />
      <LayoutRoute exact path="/gift" component={Gift} layout={App} />
      <LayoutRoute exact path="/sponsorship" component={Sponsorship} layout={App} />
      <LayoutRoute exact path="/atgmo" component={Atgmo} layout={App} />
      <LayoutRoute exact path="/users" component={UserPage} layout={App} />
      <LayoutRoute exact path="/help-request/:id/details" component={HelpRequestPageDetails} layout={App} />

      <Redirect exact from="/" to="/home" />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

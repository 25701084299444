/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification, Row, Avatar, Upload } from 'antd';
import { EditOutlined, InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';
import Dragger from 'antd/lib/upload/Dragger';

// reducers
import { fetchAllAssociations } from '../../../reducers/Association.slice';

// Config
import { API_ENDPOINT } from '../../../common/config';

// Helpers
import { localMoment } from '../../../common/helpers';

/* -------------------------------------------------------------------------- */
/*                              Association Form                              */
/* -------------------------------------------------------------------------- */
function AssociationForm({ onChange, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const [multipleFile, setMultipleFile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAssociations());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const [form] = Form.useForm();

  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    const data = new FormData();
    const handleDataObject = () => {
      // eslint-disable-next-line mdx/no-unused-expressions
      singleFile !== null && data.append('photo', singleFile);
      data.append('email_contact', entry.email_contact);
      data.append('phone_contact', entry.phone_contact);
      data.append('title_fr', entry.title_fr);
      data.append('title_ar', entry.title_ar);
      data.append('description_fr', entry.description_fr);
      data.append('description_ar', entry.description_ar);
      data.append('location_fr', entry.location_fr);
      data.append('location_ar', entry.location_ar);
      data.append('latitude', entry.latitude);
      data.append('longitude', entry.longitude);
      data.append('fax_contact', entry.fax_contact);
      data.append('presidentOfAssociation_fr', entry.presidentOfAssociation_fr);
      data.append('presidentOfAssociation_ar', entry.presidentOfAssociation_ar);
      data.append('ambassador_fr', entry.ambassador_fr);
      data.append('ambassador_ar', entry.ambassador_ar);
      data.append('createdAssociationAt', entry.createdAssociationAt);
      data.append('creation_ar', entry.creation_ar);
      data.append('value_ar', entry.value_ar);
      data.append('mission_ar', entry.mission_ar);
      data.append('creation_fr', entry.creation_fr);
      data.append('value_fr', entry.value_fr);
      data.append('mission_fr', entry.mission_fr);
      data.append('legalStatus_fr', entry.legalStatus_fr);
      data.append('legalStatus_ar', entry.legalStatus_ar);
    };

    if (multipleFile !== null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < multipleFile.length; i++) {
        data.append('gallery', multipleFile[i].originFileObj);
      }
      handleDataObject(data);
    } else {
      handleDataObject(data);
    }

    if (record) {
      axios
        .put(`${API_ENDPOINT}/v1/api/associations/${record._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Association',
            description: 'Association mise à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllAssociations());
        })
        .catch((error) =>
          notification.error({
            message: 'Association',
            description: error.message,
          }),
        )
        .then(unwrapResult);
    } else {
      axios
        .post(`${API_ENDPOINT}/v1/api/association`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Association',
            description: "L'association a été créée avec succès",
          });
          setShowModal(!showModal);
          dispatch(fetchAllAssociations());
        })
        .catch((error) =>
          notification.error({
            message: 'Association',
            description: error.message,
          }),
        );
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const ASSOCIATION_COMMUN_FIELD = [
    {
      key: 'email_contact',
      label: 'Adresse e-mail',
      placeholder: "Courriel de l'association",
      initialValue: record?.email_contact,
      colSpan: 2,
      rules: [
        {
          type: 'email',
          message: "L'entrée n'est pas valide E-mail!",
        },
        {
          required: true,
          message: 'Veuillez saisir votre e-mail !',
        },
      ],
    },
  ];
  const ASSOCIATION_FIELD = {
    columns: 2,
    fields: [
      {
        key: 'title_fr',
        placeholder: "Nom de l'association",
        label: "Nom de l'association",
        initialValue: record?.title_fr,
        rules: [
          {
            required: true,
            message: "Nom de l'association est requis",
          },
        ],
      },
      {
        key: 'title_ar',
        placeholder: 'اسم الجمعية',
        label: 'اسم الجمعية',
        initialValue: record?.title_ar,
        rules: [
          {
            required: true,
            message: 'اسم الجمعية مطلوب',
          },
        ],
      },
      {
        key: 'description_fr',
        placeholder: 'Description',
        label: 'Description',
        initialValue: record?.description_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Description est requis',
          },
        ],
      },
      {
        key: 'description_ar',
        placeholder: 'وصف الجمعية',
        label: 'وصف الجمعية',
        initialValue: record?.description_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'وصف الجمعية مطلوب',
          },
        ],
      },
      {
        key: 'createdAssociationAt',
        placeholder: 'Date de creation',
        label: 'Date de creation',
        initialValue: record ? localMoment(record?.createdAssociationAt) : localMoment(),
        widget: 'date-picker',
        colSpan: 2,
        rules: [
          {
            required: true,
            message: 'Date de creation est requis',
          },
        ],
      },
      {
        key: 'creation_fr',
        placeholder: 'Creation',
        label: 'Creation',
        initialValue: record?.creation_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Creation est requis',
          },
        ],
      },
      {
        key: 'creation_ar',
        placeholder: 'تأسيس',
        label: 'تأسيس',
        initialValue: record?.creation_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'مطلوب تأسيس',
          },
        ],
      },
      {
        key: 'value_fr',
        placeholder: 'Valeur',
        label: 'Valeur',
        initialValue: record?.value_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Valeur est requis',
          },
        ],
      },
      {
        key: 'value_ar',
        placeholder: 'القيمة',
        label: 'القيمة',
        initialValue: record?.value_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'القيمة مطلوبة',
          },
        ],
      },
      {
        key: 'mission_fr',
        placeholder: 'Mission',
        label: 'Mission',
        initialValue: record?.mission_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Mission est requis',
          },
        ],
      },
      {
        key: 'mission_ar',
        placeholder: 'مهمة',
        label: 'مهمة',
        initialValue: record?.mission_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'مهمة مطلوبة',
          },
        ],
      },
      {
        key: 'legalStatus_fr',
        placeholder: 'Statut juridique',
        label: 'Statut juridique',
        initialValue: record?.legalStatus_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Statut juridique est requis',
          },
        ],
      },
      {
        key: 'legalStatus_ar',
        placeholder: 'الوضع القانوني',
        label: 'الوضع القانوني',
        initialValue: record?.legalStatus_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'الوضع القانوني مطلوب',
          },
        ],
      },
      {
        key: 'presidentOfAssociation_fr',
        placeholder: "Président(e) de l'association",
        label: 'Président(e)',
        initialValue: record?.presidentOfAssociation_fr,
        rules: [
          {
            required: true,
            message: "Président(e) de l'association est requis",
          },
        ],
      },
      {
        key: 'presidentOfAssociation_ar',
        placeholder: 'رئيس الجمعية',
        label: 'رئيس الجمعية',
        initialValue: record?.presidentOfAssociation_ar,
        rules: [
          {
            required: true,
            message: 'رئيس الجمعية مطلوب',
          },
        ],
      },
      {
        key: 'ambassador_fr',
        placeholder: "Vis-à-vis avec l'association",
        label: 'Vis-à-vis',
        initialValue: record?.ambassador_fr,
        rules: [
          {
            required: true,
            message: "Vis-à-vis avec l'association est requis",
          },
        ],
      },
      {
        key: 'ambassador_ar',
        placeholder: 'سفير الجمعية',
        label: 'سفير الجمعية',
        initialValue: record?.ambassador_ar,
        rules: [
          {
            required: true,
            message: 'سفير الجمعية مطلوب',
          },
        ],
      },
      {
        key: 'location_fr',
        placeholder: 'Adresse',
        label: 'Adresse complete',
        initialValue: record?.location_fr,
        rules: [
          {
            required: true,
            message: 'Adresse est requis',
          },
        ],
      },

      {
        key: 'location_ar',
        placeholder: 'العنوان',
        label: 'العنوان كامل',
        initialValue: record?.location_ar,
        rules: [
          {
            required: true,
            message: 'العنوان كامل مطلوب',
          },
        ],
      },
      {
        key: 'latitude',
        placeholder: 'Latitude',
        label: 'Latitude',
        initialValue: record?.latitude ?? '0',
      },
      {
        key: 'longitude',
        placeholder: 'Longitude',
        label: 'Longitude',
        initialValue: record?.longitude ?? '0',
      },
      {
        key: 'phone_contact',
        placeholder: 'Numéro de téléphone',
        label: 'Numéro de téléphone',
        initialValue: record?.phone_contact ?? '',
        colSpan: 2,
        extra: "Si vous avez plus d'un numéro, mettez après chaque numéro un '-' exemple +216........- +216........",
      },
      {
        key: 'fax_contact',
        placeholder: 'Numéro de fax',
        label: 'Numéro de fax',
        initialValue: record?.fax_contact ?? '',
        colSpan: 2,
        extra: "Si vous avez plus d'un numéro, mettez après chaque numéro un '-' exemple +216........- +216........",
      },
    ],
  };
  // For photo
  const uploadSingleFile = (data) => {
    setSingleFile(data.fileList[0].originFileObj);
  };
  // For Multiple images
  const uplodMultipleFiles = (data) => {
    setMultipleFile(data.fileList);
  };

  const props = {
    beforeUpload: () => {
      return false;
    },
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={onClickSubmit}
          onValuesChange={onChange}
          form={form}
          encType="multipart/form-data"
        >
          <Row align="middle" justify="center">
            {record?.photo && <Avatar size={120} src={`${API_ENDPOINT}/${record.photo}`} />}
          </Row>
          <Row align="middle" justify="center">
            <Form.Item valuePropName="photo">
              <Upload onChange={uploadSingleFile} {...props} va accept="image/*" maxCount={1}>
                <Button icon={<UploadOutlined />}>
                  {record
                    ? "Cliquez pour changer l'avatar de l'association"
                    : "Cliquez pour télécharger l'avatar de l'association"}
                </Button>
              </Upload>
            </Form.Item>
          </Row>
          <Form.Item valuePropName="gallery">
            <Dragger {...props} onChange={uplodMultipleFiles} accept="image/*" maxCount={4} multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Cliquez ou faites glisser le fichier dans cette zone pour le télécharger galerie d&apos;image
              </p>
              <p className="ant-upload-hint">4 images maximum</p>
            </Dragger>
          </Form.Item>
          <FormBuilder form={form} meta={ASSOCIATION_COMMUN_FIELD} />
          <FormBuilder form={form} meta={ASSOCIATION_FIELD} />
          <Row align="center" justify="center">
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

AssociationForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onChange: PropTypes.func,
};

AssociationForm.defaultProps = {
  isCreatedForm: false,
};

export default AssociationForm;

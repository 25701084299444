/* eslint-disable no-param-reassign */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Config
import { API_ENDPOINT } from '../common/config';

// fetch All associations
export const fetchAllAssociations = createAsyncThunk(
  'association/fetchAllAssociations',
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        method: 'get',
        url: `${API_ENDPOINT}/v1/api/associations`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// fetch single association
export const fetchAssociation = createAsyncThunk('association/fetchAssociation', async (id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'get',
      url: `${API_ENDPOINT}/v1/api/associations/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Create new association
export const createAssociation = createAsyncThunk(
  'association/createAssociation',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'post',
        url: `${API_ENDPOINT}/v1/api/association`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          // 'Content-Type': 'multipart/form-data',
        },
        data,
      };

      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Update association
export const updateAssociation = createAsyncThunk(
  'association/updateAssociation',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        method: 'put',
        url: `${API_ENDPOINT}/v1/api/associations/${data._id}`,
        data: data.fields,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      };
      const payload = await axios(config);
      return payload.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

// Delete association
export const deleteAssociation = createAsyncThunk('association/deleteAssociation', async (_id, { rejectWithValue }) => {
  try {
    const config = {
      method: 'delete',
      url: `${API_ENDPOINT}/v1/api/associations/${_id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    };
    const payload = await axios(config);
    return payload.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

// Association slice
const Association = createSlice({
  name: 'Association',
  initialState: {
    associations: [],
    association: null,
    loading: false,
  },
  extraReducers: {
    [fetchAllAssociations.fulfilled]: (state, action) => {
      state.associations = action.payload;
      state.loading = false;
    },
    [fetchAllAssociations.pending]: (state) => {
      state.loading = true;
    },
    [fetchAssociation.fulfilled]: (state, action) => {
      state.association = action.payload;
      state.loading = false;
    },
    [fetchAssociation.pending]: (state) => {
      state.loading = true;
    },
  },
});

export default Association.reducer;

// Selectors
export const selectAllAssociations = (state) => state.Association.associations;
export const selectAssociation = (state) => state.Association.association;
export const selectAssociationLoading = (state) => state.Association.loading;

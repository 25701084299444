/* eslint-disable no-shadow */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';

// Redux
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Avatar, Button, Table, Empty, notification, Modal, PageHeader, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { Pie } from '@ant-design/plots';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

// reducers
import { fetchDonnationByHelpRequestId, selectDonnationHR, updateDonnation } from '../../../reducers/Donnation.slice';

// Config & helpers
import { localMoment, RetriveUserAvatar } from '../../../common/helpers';

// constants
import { dateFormat, EXPORT_CSV_HEADERS } from '../../../common/constants';

/* -------------------------------------------------------------------------- */
/*                          Help Request Page Details                         */
/* -------------------------------------------------------------------------- */
function HelpRequestPageDetails() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  /* -------------------------------- CONSTANTS ------------------------------- */
  const location = useLocation();
  const { confirm } = Modal;
  const { record } = location.state;

  // Selectors
  const dispatch = useDispatch();
  const donnationsObject = useSelector(selectDonnationHR);

  useEffect(() => {
    dispatch(fetchDonnationByHelpRequestId(record?._id));
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const acceptBloodHelpRequest = (data) => {
    confirm({
      title: `Voulez-vous vraiment accepter "${data?.fullName} "?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          updateDonnation({
            _id: data.donationId,
            fields: {
              requestStatus: 'accepted',
            },
          }),
        )
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Demande du sang',
              description: 'Demande du sang a été accepter avec succès',
            });
            dispatch(fetchDonnationByHelpRequestId(record?._id));
          })
          .catch((err) =>
            notification.error({
              message: 'Demande du sang',
              description: err.error.message,
            }),
          );
      },
    });
  };
  const declineBloodHelpRequest = (data) => {
    confirm({
      title: `Voulez-vous vraiment refuse "${data?.fullName}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          updateDonnation({
            _id: data.donationId,
            fields: {
              requestStatus: 'declined',
            },
          }),
        )
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Demande du sang',
              description: 'Demande du sang a été refuse avec succès',
            });
            dispatch(fetchDonnationByHelpRequestId(record?._id));
          })
          .catch((err) =>
            notification.error({
              message: 'Demande du sang',
              description: err.error.message,
            }),
          );
      },
    });
  };

  const donners = donnationsObject?.donnations;

  const usersData = donners?.map((user, index) => ({
    key: index,
    userId: user.donner?._id,
    helpRequestId: user?.helpRequest,
    donationId: user?._id,
    photo: user.donner?.photo,
    fullName: user.donner?.fullName,
    email: user.donner?.email,
    phoneNumber: user.donner?.phoneNumber,
    age: user.donner?.age,
    dateOfBirth: user.donner?.dateOfBirth,
    gender: user.donner?.gender,
    governorate: user.donner?.governorate,
    municipality: user.donner?.municipality,
    createdAt: user.createdAt && localMoment(user.createdAt).format(dateFormat),
    updatedAt: user.updatedAt && localMoment(user.updatedAt).format(dateFormat),
    status: user?.requestStatus,
  }));

  const DATA_TO_DOWNLOAD = donners?.map((user) => ({
    fullName: user.donner?.fullName,
    gender: user.donner?.gender,
    age: user.donner?.age,
    dateOfBirth: user.donner?.dateOfBirth,
    email: user.donner?.email,
    phoneNumber: user.donner?.phoneNumber,
    governorate: user.donner?.governorate,
    municipality: user.donner?.municipality,
    status: user?.requestStatus,
    createdAt: user.createdAt && localMoment(user.createdAt).format(dateFormat),
    updatedAt: user.updatedAt && localMoment(user.updatedAt).format(dateFormat),
  }));

  const pendingData = donners?.filter((user) => user?.requestStatus === 'pending');
  const acceptedData = donners?.filter((user) => user?.requestStatus === 'accepted');
  const declinedData = donners?.filter((user) => user?.requestStatus === 'declined');

  /* -------------------------------------------------------------------------- */
  /*                          SEARCH && FILTER HELPERES                         */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const USER_COLUMN = [
    {
      title: 'Avatar',
      key: 'photo',
      dataIndex: 'photo',
      render: (_i, user) => <Avatar size={64} src={RetriveUserAvatar(user)} />,
    },
    {
      title: 'Nom et prénom',
      key: 'fullName',
      dataIndex: 'fullName',
      ...getColumnSearchProps('fullName'),
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Genre',
      key: 'gender',
      dataIndex: 'gender',
      ...getColumnSearchProps('gender'),
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Age',
      key: 'age',
      dataIndex: 'age',
      ...getColumnSearchProps('age'),
      sorter: (a, b) => a.age.localeCompare(b.age),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date de naissance',
      key: 'dateOfBirth',
      dataIndex: 'dateOfBirth',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Numéro de téléphone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Gouvernorat',
      key: 'governorate',
      dataIndex: 'governorate',
      ...getColumnSearchProps('governorate'),
      sorter: (a, b) => a.governorate.localeCompare(b.governorate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Municipalité',
      key: 'municipality',
      dataIndex: 'municipality',
      ...getColumnSearchProps('municipality'),
      sorter: (a, b) => a.municipality.localeCompare(b.municipality),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Statut',
      key: 'status',
      dataIndex: 'status',
      ...getColumnSearchProps('status'),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Updated At',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
    },
    {
      render: (data) => (
        <div>
          <Button
            className="full-width mb-10"
            type="primary"
            onClick={() => acceptBloodHelpRequest(data)}
            disabled={data?.status !== 'pending'}
          >
            Accepter
          </Button>
          <Button
            className="full-width"
            type="danger"
            onClick={() => declineBloodHelpRequest(data)}
            disabled={data?.status !== 'pending'}
          >
            Refuser
          </Button>
        </div>
      ),
      fixed: 'right',
    },
  ];
  /* --------------------------------- CHARTS --------------------------------- */
  const data = [
    {
      type: 'En cours',
      value: parseInt(pendingData?.length, 10),
    },
    {
      type: 'Acceptée',
      value: parseInt(acceptedData?.length, 10),
    },
    {
      type: 'Refusée',
      value: parseInt(declinedData?.length, 10),
    },
  ];
  const declinedColor = '#F4664A';
  const pendingColor = '#00FFFF';
  const acceptedColor = '#94be2a';

  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    height: 250,
    color: ({ type }) => {
      if (type === 'Acceptée') {
        return acceptedColor;
      }
      if (type === 'Refusée') {
        return declinedColor;
      }

      return pendingColor;
    },
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Personnes intéressées"
        extra={
          donners?.length !== 0 && [
            <Button key="1" type="dashed">
              <CSVLink
                data={DATA_TO_DOWNLOAD ?? []}
                headers={EXPORT_CSV_HEADERS}
                filename={`${record?.title_fr}-export.csv`}
              >
                Exporter les personnes intéressées
              </CSVLink>
            </Button>,
          ]
        }
      />

      {donners?.length === 0 ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 250,
          }}
          description="Aucune personne intéressées"
        />
      ) : (
        <>
          <Pie {...config} />
          <Table columns={USER_COLUMN} dataSource={usersData} scroll={{ x: 2200 }} />
        </>
      )}
    </div>
  );
}

HelpRequestPageDetails.propTypes = {
  record: PropTypes.object,
};

export default HelpRequestPageDetails;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// Packages
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';

// redux
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Layout, notification, Row } from 'antd';
import { SwapLeftOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import { selectSessionLoading } from '../../../reducers/Session.slice';

// config
import { API_ENDPOINT } from '../../../common/config';

// Local Images
import LOGO from '../../../assets/atgmo-logo.png';

/* -------------------------------------------------------------------------- */
/*                                 Login Page                                 */
/* -------------------------------------------------------------------------- */
function ForgotPasswordPage() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const isLoading = useSelector(selectSessionLoading);
  const [emailSent, setEmailSent] = useState(false);

  /* ---------------------------------- CONST --------------------------------- */
  const resetFormFields = {
    fields: [
      {
        key: 'email',
        placeholder: 'Adresse e-mail',
        rules: [
          {
            type: 'email',
            message: "L'entrée n'est pas valide E-mail!",
          },
          {
            required: true,
            message: 'Veuillez saisir votre e-mail !',
          },
        ],
      },
    ],
  };
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const onSubmit = (values) => {
    // clear all localstorage
    window.localStorage.clear();
    axios
      .post(`${API_ENDPOINT}/v1/api/auth/user/password/forget`, values)
      .then(() => {
        notification.success({
          message: 'Utilisateur',
          description: "Veuillez vérifier votre e-mail pour plus d'instructions",
        });
        setEmailSent(true);
      })
      .catch(() =>
        notification.error({
          message: 'Utilisateur',
          description: 'Utilisateur non trouvé',
        }),
      )
      .then(unwrapResult);
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <Layout className="forgot-password-page">
      <Layout.Content>
        <Link to="/home">
          <Button type="primary">
            <SwapLeftOutlined />
          </Button>
        </Link>
        <Row align="center" justify="center">
          <img src={LOGO} className="atgmo-logo" alt="AtGmo" />
        </Row>
        <Row align="center" justify="center">
          {emailSent ? (
            <p className="form-message">Veuillez vérifier votre e-mail pour plus d&apos;instructions</p>
          ) : (
            <Form name="forgot-password-form" onFinish={onSubmit} form={form} className="login-page-layout">
              <p className="form-message">Veuillez saisir votre adresse e-mail pour rechercher votre compte.</p>
              <FormBuilder form={form} meta={resetFormFields} />
              <Form.Item>
                <Button htmlType="submit" type="primary" className="login-button" loading={isLoading}>
                  CONNEXION
                </Button>
              </Form.Item>
            </Form>
          )}
        </Row>
      </Layout.Content>
    </Layout>
  );
}

ForgotPasswordPage.propTypes = {
  onSubmit: PropTypes.func,
};

export default ForgotPasswordPage;

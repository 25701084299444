/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification, Upload, Row, Avatar } from 'antd';
import { EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import { fetchAllDiseases } from '../../../reducers/disease.slice';

// config
import { API_ENDPOINT } from '../../../common/config';

/* -------------------------------------------------------------------------- */
/*                                Disease Form                                */
/* -------------------------------------------------------------------------- */
function DiseaseForm({ onChange, onlyFormItems, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllDiseases());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const [form] = Form.useForm();

  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    const data = new FormData();
    // eslint-disable-next-line mdx/no-unused-expressions
    singleFile !== null && data.append('photo', singleFile);
    data.append('title_ar', entry.title_ar);
    data.append('title_fr', entry.title_fr);
    data.append('description_ar', entry.description_ar);
    data.append('description_fr', entry.description_fr);
    if (record) {
      axios
        .put(`${API_ENDPOINT}/v1/api/blood/diseases/${record._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Maladie',
            description: 'Maladie mise à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllDiseases());
        })
        .catch((error) =>
          notification.error({
            message: 'Maladie',
            description: error.message,
          }),
        )
        .then(unwrapResult);
      form.resetFields();
    } else {
      axios
        .post(`${API_ENDPOINT}/v1/api/blood/disease`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          notification.success({
            message: 'Maladie',
            description: response.data.message,
          });
          setShowModal(!showModal);
          dispatch(fetchAllDiseases());
        })
        .catch((error) =>
          notification.error({
            message: 'Maladie',
            description: error,
          }),
        );
      form.resetFields();
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const QUESTION_AND_ANSWER_FIELD = {
    columns: 2,
    fields: [
      {
        key: 'title_fr',
        placeholder: 'Nom de la maladie',
        label: 'Nom de la maladie',
        initialValue: record?.title_fr,
        rules: [
          {
            required: true,
            message: 'Le nom de la maladie est obligatoire',
          },
        ],
      },
      {
        key: 'title_ar',
        placeholder: 'اسم المرض',
        label: 'اسم المرض',
        initialValue: record?.title_ar,
        rules: [
          {
            required: true,
            message: 'اسم المرض مطلوب',
          },
        ],
      },
      {
        key: 'description_fr',
        placeholder: 'Description de la maladie',
        label: 'Description ',
        initialValue: record?.description_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'La description de la maladie est obligatoire',
          },
        ],
      },
      {
        key: 'description_ar',
        placeholder: 'وصف المرض ',
        label: 'وصف المرض ',
        initialValue: record?.description_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'وصف المرض  مطلوب',
          },
        ],
      },
    ],
  };
  const uploadSingleFile = (data) => {
    setSingleFile(data.fileList[0].originFileObj);
  };

  const props = {
    beforeUpload: () => {
      return false;
    },
  };
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div className="disease-form">
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={onClickSubmit}
          onValuesChange={onChange}
          form={form}
          encType="multipart/form-data"
        >
          <Row align="middle" justify="center">
            {record?.photo && <Avatar size={120} src={`${API_ENDPOINT}/${record.photo}`} />}
          </Row>
          <Row align="middle" justify="center">
            <Form.Item valuePropName="photo">
              <Upload onChange={uploadSingleFile} {...props} accept="image/*" maxCount={1}>
                <Button icon={<UploadOutlined />}>
                  {record ? "Cliquez pour changer l'image de maladie" : "Cliquez pour télécharger l'image de maladie"}
                </Button>
              </Upload>
            </Form.Item>
          </Row>

          <FormBuilder form={form} meta={QUESTION_AND_ANSWER_FIELD} />

          <Row align="middle" justify="center">
            {!onlyFormItems && (
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

DiseaseForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

DiseaseForm.defaultProps = {
  isCreatedForm: false,
};

export default DiseaseForm;

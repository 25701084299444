/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Avatar, Empty, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { deleteAssociation, fetchAllAssociations, selectAllAssociations } from '../../reducers/Association.slice';

// local components
import AssociationForm from './AssociationForm';

// Config
import { API_ENDPOINT } from '../../common/config';
import { RetriveImage } from '../../common/helpers';

// Local images
import EMPTY_IMAGE from '../../assets/empty.png';

/* -------------------------------------------------------------------------- */
/*                              Association Page                              */
/* -------------------------------------------------------------------------- */
function AssociationComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const [loading, setLoading] = useState(true);
  // Selectors
  const dispatch = useDispatch();
  const AssociationObject = useSelector(selectAllAssociations);

  useEffect(() => {
    dispatch(fetchAllAssociations());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeAssociation = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${v.truncate(data?.title_fr, 50)}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteAssociation(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: "Supprimer l'Association",
              description: "L'Association a été supprimée avec succès",
            });
            dispatch(fetchAllAssociations());
          })
          .catch((err) =>
            notification.error({
              message: "Supprimer l'Association",
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const Associations = AssociationObject && AssociationObject.associations;

  const AssociationsData = Associations?.map((assoc, index) => ({
    // in common
    key: index,
    _id: assoc?._id,
    gallery: assoc?.gallery,
    photo: assoc?.photo,
    email_contact: assoc?.email_contact,
    phone_contact: assoc?.phone_contact,
    fax_contact: assoc?.fax_contact,
    latitude: assoc.coordinates?.latitude,
    longitude: assoc.coordinates?.longitude,
    createdAssociationAt: assoc?.createdAssociationAt,
    // for arab lang
    title_ar: assoc.ar?.title,
    description_ar: assoc.ar?.description,
    address_contact_ar: assoc.ar?.address_contact,
    location_ar: assoc.ar?.location,
    presidentOfAssociation_ar: assoc.ar?.presidentOfAssociation,
    ambassador_ar: assoc.ar?.ambassador,
    creation_ar: assoc.ar?.creation,
    value_ar: assoc.ar?.value,
    mission_ar: assoc.ar?.mission,
    legalStatus_ar: assoc.ar?.legalStatus,
    // for french lang
    title_fr: assoc.fr?.title,
    description_fr: assoc.fr?.description,
    address_contact_fr: assoc.fr?.address_contact,
    location_fr: assoc.fr?.location,
    presidentOfAssociation_fr: assoc.fr?.presidentOfAssociation,
    ambassador_fr: assoc.fr?.ambassador,
    creation_fr: assoc.fr?.creation,
    value_fr: assoc.fr?.value,
    mission_fr: assoc.fr?.mission,
    legalStatus_fr: assoc.fr?.legalStatus,
  }));

  const BLOOD_TYPES_COLUMN = [
    {
      title: 'Galerie',
      key: 'gallery',
      dataIndex: 'gallery',
      fixed: 'left',
      render: (gallery) => (
        <>
          {gallery.length !== 0 ? (
            <Avatar.Group maxCount={5}>
              {gallery?.map((photo, i) => (
                <Avatar size={42} key={i} src={`${API_ENDPOINT}/${photo}`} />
              ))}
            </Avatar.Group>
          ) : (
            <Avatar size={42} src={EMPTY_IMAGE} alt="no gallery" />
          )}
        </>
      ),
    },
    {
      title: 'Bannière',
      key: 'photo',
      dataIndex: 'photo',
      render: (photo) => <Avatar size={42} src={RetriveImage(photo)} />,
    },
    {
      title: 'E-mail',
      key: 'email_contact',
      dataIndex: 'email_contact',
    },
    {
      title: "Nom de l'association",
      key: 'title_fr',
      dataIndex: 'title_fr',
    },
    {
      title: 'Description',
      key: 'description_fr',
      dataIndex: 'description_fr',
      render: (Description) => v.truncate(Description, 150),
    },
    {
      title: 'Valeur',
      key: 'value_fr',
      dataIndex: 'value_fr',
      render: (Value) => v.truncate(Value, 150),
    },
    {
      title: 'Mission',
      key: 'mission_fr',
      dataIndex: 'mission_fr',
      render: (Mission) => v.truncate(Mission, 150),
    },
    {
      title: 'Statut juridique',
      key: 'legalStatus_fr',
      dataIndex: 'legalStatus_fr',
      render: (legalStatus) => v.truncate(legalStatus, 150),
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <AssociationForm record={record} />
          </Col>
          <Col className="mr">
            <Button onClick={() => removeAssociation(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {Associations?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucune association trouvée"
            >
              <AssociationForm label="Créer association" isCreatedForm />
            </Empty>
          ) : (
            <>
              {Associations?.length < 1 && <AssociationForm label="Créer une nouvelle Association" isCreatedForm />}
              <Table columns={BLOOD_TYPES_COLUMN} dataSource={AssociationsData} scroll={{ x: 1400 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AssociationComponent;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification, Row } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import {
  createQuestionAndAnswer,
  fetchAllQuestionAndAnswers,
  updateQuestionAndAnswer,
} from '../../../reducers/questionAndAnswer.slice';

/* -------------------------------------------------------------------------- */
/*                          Question And Answer Form                          */
/* -------------------------------------------------------------------------- */
function QuestionAndAnswerForm({ onChange, onlyFormItems, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllQuestionAndAnswers());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  const [form] = Form.useForm();

  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    if (record) {
      dispatch(
        updateQuestionAndAnswer({
          _id: record._id,
          fields: {
            ...entry,
          },
        }),
      )
        .then(() => {
          notification.success({
            message: 'Question et réponse',
            description: 'Question et réponse Mis à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllQuestionAndAnswers());
        })
        .catch((error) =>
          notification.error({
            message: 'Question et réponse',
            description: error.message,
          }),
        )
        .then(unwrapResult);
      form.resetFields();
    } else {
      dispatch(
        createQuestionAndAnswer({
          ...entry,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          notification.success({
            message: 'Question et réponse',
            description: 'La question et la réponse ont été créées avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllQuestionAndAnswers());
        })
        .catch((error) =>
          notification.error({
            message: 'Question et réponse',
            description: error.message,
          }),
        );
      form.resetFields();
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const QUESTION_AND_ANSWER_FIELD = {
    columns: 2,
    fields: [
      {
        key: 'question_fr',
        placeholder: 'Question',
        label: 'Question',
        initialValue: record?.question_fr,
        rules: [
          {
            required: true,
            message: 'La question est obligatoire',
          },
        ],
      },
      {
        key: 'question_ar',
        placeholder: 'السؤال',
        label: 'السؤال',
        initialValue: record?.question_ar,
        rules: [
          {
            required: true,
            message: 'السؤال مطلوب',
          },
        ],
      },
      {
        key: 'answer_fr',
        placeholder: 'Réponse',
        label: 'Réponse',
        initialValue: record?.answer_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'La réponse est obligatoire',
          },
        ],
      },
      {
        key: 'answer_ar',
        placeholder: 'الجواب',
        label: 'الجواب',
        initialValue: record?.answer_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'الجواب مطلوب',
          },
        ],
      },
      {
        key: 'link',
        placeholder: 'Lien',
        label: 'Lien',
        initialValue: record?.link ?? '',
        colSpan: 2,
        rules: [
          {
            type: 'url',
            message: 'Ce champ doit être une URL valide.',
          },
        ],
        extra: 'Exemple pour URL valide http://www.example.com',
      },
    ],
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={(values) => onClickSubmit(values)}
          onValuesChange={onChange}
          form={form}
          encType="multipart/form-data"
        >
          <FormBuilder form={form} meta={QUESTION_AND_ANSWER_FIELD} />

          <Row align="middle" justify="center">
            {!onlyFormItems && (
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

QuestionAndAnswerForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onChange: PropTypes.func,
  onlyFormItems: PropTypes.bool,
};

QuestionAndAnswerForm.defaultProps = {
  isCreatedForm: false,
};

export default QuestionAndAnswerForm;

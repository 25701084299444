/* eslint-disable no-shadow */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Tag, Empty, Avatar, Input, Space, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

// reducers
import { deleteBloodbank, fetchAllHelpRequests, selectAllHelpRequests } from '../../reducers/HelpRequest.slice';
import { fetchAllDonnations, selectAllDonnations } from '../../reducers/Donnation.slice';

// local components
import MaterialHelpRequestForm from './MaterialHelpRequestForm';

// Local images
import EMPTY_IMAGE from '../../assets/empty.png';

// helpers, config && constants
import { API_ENDPOINT } from '../../common/config';
import { localMoment } from '../../common/helpers';
import { dateFormat } from '../../common/constants';

/* -------------------------------------------------------------------------- */
/*                          Question And Answer Page                          */
/* -------------------------------------------------------------------------- */
function MaterialHelpRequestComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [loading, setLoading] = useState(true);
  const { confirm } = Modal;
  // Selectors
  const MATERIAL_HELP_REQUEST_OBJECT = useSelector(selectAllHelpRequests);
  const ALL_HR = useSelector(selectAllDonnations);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllHelpRequests());
    dispatch(fetchAllDonnations());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removeBloodHelpRequest = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${v.truncate(data?.title_fr, 50)}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteBloodbank(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Supprimer Demande de parrainage',
              description: 'Demande de parrainage ont été supprimées avec succès',
            });
            dispatch(fetchAllHelpRequests());
          })
          .catch((err) =>
            notification.error({
              message: 'Supprimer Demande de parrainage',
              description: err.error.message,
            }),
          );
      },
    });
  };

  function getTotalInterestedNumber(record) {
    const filteredDonnation = ALL_HR?.donnations?.filter((don) => don.helpRequest === record._id);
    return <Tag color="green">{filteredDonnation?.length}</Tag>;
  }

  /* -------------------------------- CONSTANTS ------------------------------- */
  const HELP_REQUEST = MATERIAL_HELP_REQUEST_OBJECT && MATERIAL_HELP_REQUEST_OBJECT.helpRequests;

  const HELP_REQUEST_DATA = HELP_REQUEST?.filter((hr) => !hr.isBloodRequest).map((helpRequest, index) => ({
    // in common
    key: index,
    _id: helpRequest._id,
    helpRequest,
    donners: helpRequest?.donners,
    gallery: helpRequest?.gallery,
    link: helpRequest?.link,
    createdAt: helpRequest.createdAt && localMoment(helpRequest.createdAt).format(dateFormat),
    updatedAt: helpRequest.updatedAt && localMoment(helpRequest.updatedAt).format(dateFormat),
    // for arab lang
    title_ar: helpRequest.ar.title,
    description_ar: helpRequest.ar?.description,
    gouvernorat_ar: helpRequest.ar?.gouvernorat,
    municipalitie_ar: helpRequest.ar?.municipalitie,
    status_ar: helpRequest.ar?.status,
    address_ar: helpRequest.ar?.address,
    sector_ar: helpRequest.ar?.sector,
    service_ar: helpRequest.ar?.service,
    // for french lang
    title_fr: helpRequest.fr.title,
    description_fr: helpRequest.fr.description,
    gouvernorat_fr: helpRequest.fr?.gouvernorat,
    municipalitie_fr: helpRequest.fr?.municipalitie,
    status_fr: helpRequest.fr?.status,
    address_fr: helpRequest.fr?.address,
    isArchived: helpRequest?.isArchived,
    sector_fr: helpRequest.fr?.sector,
    service_fr: helpRequest.fr?.service,
  }));

  /* -------------------------------------------------------------------------- */
  /*                          SEARCH && FILTER HELPERES                         */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const HELP_REQUEST_COLUMN = [
    {
      title: 'Galerie',
      key: 'gallery',
      dataIndex: 'gallery',
      fixed: 'left',
      render: (gallery) => (
        <>
          {gallery.length !== 0 ? (
            <Avatar.Group maxCount={5}>
              {gallery?.map((photo, i) => (
                <Avatar size={42} key={i} src={`${API_ENDPOINT}/${photo}`} />
              ))}
            </Avatar.Group>
          ) : (
            <Avatar size={42} src={EMPTY_IMAGE} alt="no gallery" />
          )}
        </>
      ),
    },
    {
      title: 'Titre',
      key: 'title_fr',
      dataIndex: 'title_fr',
      fixed: 'left',
      render: (title) => v.truncate(title, 45),
      ...getColumnSearchProps('title_fr'),
      sorter: (a, b) => a.title_fr.localeCompare(b.title_fr),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Gouvernorat',
      key: 'gouvernorat_fr',
      dataIndex: 'gouvernorat_fr',
      ...getColumnSearchProps('gouvernorat_fr'),
      sorter: (a, b) => a.gouvernorat_fr.localeCompare(b.gouvernorat_fr),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Service',
      key: 'service_fr',
      dataIndex: 'service_fr',
      ...getColumnSearchProps('service_fr'),
      sorter: (a, b) => a.service_fr.localeCompare(b.service_fr),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Priorité',
      key: 'status_fr',
      dataIndex: 'status_fr',
      ...getColumnSearchProps('status_fr'),
      sorter: (a, b) => a.status_fr.localeCompare(b.status_fr),
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        switch (status) {
          case 'Critique':
            return <Tag color="#f50">Critique</Tag>;
          case 'Forte':
            return <Tag color="red">Forte</Tag>;
          case 'Moyenne':
            return <Tag color="lime">Moyenne</Tag>;
          default:
            return <Tag color="#87d068">Faible</Tag>;
        }
      },
    },
    {
      title: 'Personnes intéressées',
      render: (record) => (
        <div>
          {getTotalInterestedNumber(record)}
          <Link to={{ pathname: `/help-request/${record._id}/details`, state: { record } }}>Plus de détails</Link>
        </div>
      ),
    },
    {
      title: 'Date d’insertion',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Statut',
      key: 'isArchived',
      dataIndex: 'isArchived',
      sorter: (a, b) => a.isArchived - b.isArchived,
      sortDirections: ['descend', 'ascend'],
      render: (archived) => (archived === true ? 'Masquée' : 'Publiée'),
    },
    {
      title: 'dernière mise à jour',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col className="mr">
            <MaterialHelpRequestForm record={record} />
          </Col>
          <Col className="mr">
            <Button type="danger" onClick={() => removeBloodHelpRequest(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
      fixed: 'right',
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {HELP_REQUEST_DATA?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucune demande de parrainage n'a été trouvée"
            >
              <MaterialHelpRequestForm label="Créer une nouvelle demande de parrainage" isCreatedForm />
            </Empty>
          ) : (
            <>
              <MaterialHelpRequestForm label="Créer une nouvelle demande de parrainage" isCreatedForm />
              <Table columns={HELP_REQUEST_COLUMN} dataSource={HELP_REQUEST_DATA} scroll={{ x: 2000 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MaterialHelpRequestComponent;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import v from 'voca';

// Redux
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

// UI Components
import { Table, Row, Col, Button, Modal, notification, Empty, Skeleton } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// reducers
import { deletePsychologist, fetchAllPsychologists, selectAllPsychologists } from '../../reducers/Psychologist.slice';

// local components
import PsychologistForm from './PsychologistForm';

/* -------------------------------------------------------------------------- */
/*                            Blood Bank Component                            */
/* -------------------------------------------------------------------------- */
function PsychologistComponent() {
  /* ---------------------------------- HOOKS --------------------------------- */
  const { confirm } = Modal;
  const [loading, setLoading] = useState(true);
  // Selectors
  const PsychologistObject = useSelector(selectAllPsychologists);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPsychologists());
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const removePsychologist = (data) => {
    confirm({
      title: `Voulez-vous vraiment supprimer "${data?.fullName_fr}"?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deletePsychologist(data._id))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: 'Supprimer la psychologue',
              description: 'La psychologue a été supprimées avec succès',
            });
            dispatch(fetchAllPsychologists());
          })
          .catch((err) =>
            notification.error({
              message: 'Supprimer la psychologue',
              description: err.error.message,
            }),
          );
      },
    });
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const PSYCHOLOGIST = PsychologistObject && PsychologistObject.psychologists;

  const PSYCHOLOGIST_DATA = PSYCHOLOGIST?.map((psy, index) => ({
    // in common
    key: index,
    _id: psy._id,
    phoneNumber: psy.phoneNumber,
    faxNumber: psy.faxNumber,
    link: psy.link,
    email: psy.email,
    latitude: psy.coordinates?.latitude,
    longitude: psy.coordinates?.longitude,
    // for arab lang
    fullName_ar: psy.ar.fullName,
    speciality_ar: psy.ar.speciality,
    address_ar: psy.ar.address,
    // for french lang
    fullName_fr: psy.fr.fullName,
    speciality_fr: psy.fr.speciality,
    address_fr: psy.fr.address,
  }));

  const PSYCHOLOGIST_COLUMN = [
    {
      title: 'Nom',
      key: 'fullName_fr',
      dataIndex: 'fullName_fr',
    },
    {
      title: 'Description',
      key: 'speciality_fr',
      dataIndex: 'speciality_fr',
      render: (speciality) => v.truncate(speciality, 20),
    },
    {
      title: 'Adresse',
      key: 'address_fr',
      dataIndex: 'address_fr',
      render: (address) => v.truncate(address, 20),
    },
    {
      title: 'Numéro de téléphone',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Numéro de fax',
      key: 'faxNumber',
      dataIndex: 'faxNumber',
    },
    {
      render: (record) => (
        <Row align="middle" justify="end">
          <Col>
            <PsychologistForm record={record} />
          </Col>
          <Col className="mr">
            <Button type="danger" onClick={() => removePsychologist(record)} danger>
              <DeleteOutlined />
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {PSYCHOLOGIST_DATA?.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 250,
              }}
              description="aucune psychologue trouvée"
            >
              <PsychologistForm label="Créer une nouvelle psychologue" isCreatedForm />
            </Empty>
          ) : (
            <>
              <PsychologistForm label="Créer une nouvelle psychologue" isCreatedForm />
              <Table columns={PSYCHOLOGIST_COLUMN} dataSource={PSYCHOLOGIST_DATA} scroll={{ x: 1400 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PsychologistComponent;

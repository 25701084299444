/* eslint-disable camelcase */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

// UI Components
import { Form, Button, Modal, notification, Row, Avatar } from 'antd';
import { EditOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import Dragger from 'antd/lib/upload/Dragger';
import { fetchAllHelpRequests, selectHelpRequestLoading } from '../../../reducers/HelpRequest.slice';

// config & helpers
import { API_ENDPOINT } from '../../../common/config';
import { fetchData } from '../../../common/helpers';

// constants
import {
  region,
  REGION_AR,
  GOUVERNORAT_DATA,
  GOUVERNORAT_DATA_AR,
  SECTOR,
  SECTOR_T_AR,
  SECTOR_DATA,
  SECTOR_DATA_AR,
  status,
  STATUS_AR,
} from '../../../common/constants';

/* -------------------------------------------------------------------------- */
/*                          Question And Answer Form                          */
/* -------------------------------------------------------------------------- */
function MaterialHelpRequestForm({ onlyFormItems, isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();

  /* -------------------------------------------------------------------------- */
  /*                         GOUVERNORAT/MUNICIPALITIES                         */
  /* -------------------------------------------------------------------------- */
  const GOUVERNORAT_AR = form.getFieldValue('gouvernorat_ar');
  const GOUVERNORAT_FR = form.getFieldValue('gouvernorat_fr');
  const [MUNICIPALITIES_AR, SETMUNICIPALITIES_AR] = useState({});
  const [MUNICIPALITIES_FR, SETMUNICIPALITIES_FR] = useState({});
  const loading = GOUVERNORAT_FR && !MUNICIPALITIES_FR[GOUVERNORAT_FR];

  const forceUpdate = FormBuilder.useForceUpdate();

  useEffect(() => {
    if (GOUVERNORAT_FR && !MUNICIPALITIES_FR[GOUVERNORAT_FR]) {
      fetchData(GOUVERNORAT_DATA, GOUVERNORAT_FR).then((arr) => {
        SETMUNICIPALITIES_FR((p) => ({ ...p, [GOUVERNORAT_FR]: arr }));
      });
    }
  }, [GOUVERNORAT_FR, SETMUNICIPALITIES_FR, MUNICIPALITIES_FR]);

  useEffect(() => {
    if (GOUVERNORAT_AR && !MUNICIPALITIES_AR[GOUVERNORAT_AR]) {
      fetchData(GOUVERNORAT_DATA_AR, GOUVERNORAT_AR).then((arr) => {
        SETMUNICIPALITIES_AR((p) => ({ ...p, [GOUVERNORAT_AR]: arr }));
      });
    }
  }, [GOUVERNORAT_AR, SETMUNICIPALITIES_AR, MUNICIPALITIES_AR]);

  /* -------------------------------------------------------------------------- */
  /*                               SECTOR/SERVICE                               */
  /* -------------------------------------------------------------------------- */
  const SECTOR_AR = form.getFieldValue('sector_ar');
  const SECTOR_FR = form.getFieldValue('sector_fr');
  const [SERVICE_AR, SETSERVICE_AR] = useState({});
  const [SERVICE_FR, SETSERVICE_FR] = useState({});
  const loadingSector = SECTOR_FR && !SERVICE_FR[SECTOR_FR];

  useEffect(() => {
    if (SECTOR_FR && !SERVICE_FR[SECTOR_FR]) {
      fetchData(SECTOR_DATA, SECTOR_FR).then((arr) => {
        SETSERVICE_FR((p) => ({ ...p, [SECTOR_FR]: arr }));
      });
    }
  }, [SECTOR_FR, SETSERVICE_FR, SERVICE_FR]);

  useEffect(() => {
    if (SECTOR_AR && !SERVICE_AR[SECTOR_AR]) {
      fetchData(SECTOR_DATA_AR, SECTOR_AR).then((arr) => {
        SETSERVICE_AR((p) => ({ ...p, [SECTOR_AR]: arr }));
      });
    }
  }, [SECTOR_AR, SETSERVICE_AR, SERVICE_AR]);

  /* ---------------------------------- OTHER --------------------------------- */
  const [showModal, setShowModal] = useState(false);
  const [multipleFile, setMultipleFile] = useState(null);
  const HRloading = useSelector(selectHelpRequestLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllHelpRequests());
  }, []);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    const data = new FormData();
    const handleDataObject = () => {
      data.append('title_ar', entry.title_ar);
      data.append('description_ar', entry.description_ar);
      data.append('title_fr', entry.title_fr);
      data.append('description_fr', entry.description_fr);
      data.append('gouvernorat_fr', entry.gouvernorat_fr ?? 'Tous');
      data.append('municipalitie_fr', entry.municipalitie_fr ?? 'Tous');
      data.append('gouvernorat_ar', entry.gouvernorat_ar ?? 'الجميع');
      data.append('municipalitie_ar', entry.municipalitie_ar ?? 'الجميع');
      data.append('address_fr', entry.address_fr);
      data.append('address_ar', entry.address_ar);
      data.append('sector_ar', entry.sector_ar);
      data.append('service_ar', entry.service_ar);
      data.append('sector_fr', entry.sector_fr);
      data.append('service_fr', entry.service_fr);
      data.append('status_fr', entry.status_fr);
      data.append('status_ar', entry.status_ar);
      data.append('isBloodRequest', false);
      data.append('isArchived', entry.isArchived === 'Masquée');
      data.append('link', entry.link);
    };

    if (multipleFile !== null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < multipleFile.length; i++) {
        data.append('gallery', multipleFile[i].originFileObj);
      }
      handleDataObject(data);
    } else {
      handleDataObject(data);
    }
    if (record) {
      axios
        .put(`${API_ENDPOINT}/v1/api/help-requests/${record._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Demande de sang',
            description: 'Demande de sang Mis à jour avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllHelpRequests());
        })
        .catch((error) =>
          notification.error({
            message: 'Demande de sang',
            description: error.message,
          }),
        )
        .then(unwrapResult);
      form.resetFields();
    } else {
      axios
        .post(`${API_ENDPOINT}/v1/api/help-request`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Demande de sang',
            description: 'La question et la réponse ont été créées avec succès',
          });
          setShowModal(!showModal);
          dispatch(fetchAllHelpRequests());
        })
        .catch((error) =>
          notification.error({
            message: 'Demande de sang',
            description: error.message,
          }),
        );
      form.resetFields();
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const options = ['Masquée', 'Publiée'];
  const MATERIAL_REQUEST_FIELD = {
    columns: 2,
    fields: [
      {
        key: 'title_fr',
        placeholder: 'Titre',
        label: 'Titre',
        initialValue: record?.title_fr,
        rules: [
          {
            required: true,
            message: 'Titre est obligatoire',
          },
        ],
      },
      {
        key: 'title_ar',
        placeholder: 'العنوان',
        label: 'العنوان',
        initialValue: record?.title_ar,
        rules: [
          {
            required: true,
            message: 'العنوان مطلوب',
          },
        ],
      },
      {
        key: 'description_fr',
        placeholder: 'Description',
        label: 'Description',
        initialValue: record?.description_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'Description est obligatoire',
          },
        ],
      },
      {
        key: 'description_ar',
        placeholder: 'الوصف',
        label: 'الوصف',
        initialValue: record?.description_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'الوصف مطلوب',
          },
        ],
      },
      {
        key: 'gouvernorat_fr',
        label: 'Gouvernorat',
        widget: 'select',
        options: region.map((item) => ({ label: item, value: item })),
        placeholder: 'Select Gouvernorat...',
        initialValue: record?.gouvernorat_fr,
        dynamic: true,
        widgetProps: {
          onChange: () => {
            // Clear municipality value when governorate is changed
            form.setFieldsValue({ city: undefined });
          },
        },
      },
      {
        key: 'gouvernorat_ar',
        label: 'الولاية',
        placeholder: 'إختر الولاية...',
        initialValue: record?.gouvernorat_ar,
        disabled: true,
      },
      {
        key: 'municipalitie_fr',
        label: 'Municipalitie',
        widget: 'select',
        options: GOUVERNORAT_FR ? MUNICIPALITIES_FR[GOUVERNORAT_FR] || [] : [],
        initialValue: record?.municipalitie_fr,
        placeholder: loading ? 'Loading...' : 'Select Municipalitie...',
        widgetProps: { loading },
        disabled: loading || !GOUVERNORAT_FR,
      },
      {
        key: 'municipalitie_ar',
        label: 'البلدية',
        initialValue: record?.municipalitie_ar,
        placeholder: 'إختر البلدية...',
        disabled: true,
      },
      {
        key: 'address_fr',
        placeholder: 'Adresse',
        label: 'Adresse',
        initialValue: record?.address_fr,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: "L'adresse est obligatoire",
          },
        ],
      },
      {
        key: 'address_ar',
        placeholder: 'العنوان',
        label: 'العنوان',
        initialValue: record?.address_ar,
        widget: 'textarea',
        rules: [
          {
            required: true,
            message: 'العنوان مطلوب',
          },
        ],
      },
      {
        key: 'sector_fr',
        label: 'Secteur',
        widget: 'select',
        options: SECTOR.map((item) => ({ label: item, value: item })),
        placeholder: 'Select secteur...',
        initialValue: record?.sector_fr,
        dynamic: true,
        widgetProps: {
          onChange: () => {
            // Clear municipality value when governorate is changed
            form.setFieldsValue({ city: undefined });
          },
        },
        rules: [
          {
            required: true,
            message: 'Secteur est obligatoire',
          },
        ],
      },
      {
        key: 'sector_ar',
        label: 'الخدمات',
        placeholder: 'إختر الخدمات...',
        initialValue: record?.sector_ar,
        disabled: true,
      },
      {
        key: 'service_fr',
        label: 'Service',
        widget: 'select',
        options: SECTOR_FR ? SERVICE_FR[SECTOR_FR] || [] : [],
        initialValue: record?.service_fr,
        placeholder: loadingSector ? 'Loading...' : 'Select service...',
        widgetProps: { loading },
        disabled: loadingSector || !SECTOR_FR,
        rules: [
          {
            required: true,
            message: 'Service est obligatoire',
          },
        ],
      },
      {
        key: 'service_ar',
        label: 'قطاع',
        placeholder: 'إختر قطاع...',
        initialValue: record?.service_ar,
        disabled: true,
      },
      {
        key: 'status_fr',
        label: 'Priorité',
        widget: 'select',
        options: status.map((item) => ({ label: item, value: item })),
        placeholder: 'Select Status...',
        initialValue: record?.status_fr,
        rules: [
          {
            required: true,
            message: 'Priorité est obligatoire',
          },
        ],
      },
      {
        key: 'status_ar',
        label: 'الأولوية',
        placeholder: 'إختر الأولوية...',
        initialValue: record?.status_ar,
        disabled: true,
      },
      {
        key: 'link',
        placeholder: 'Lien',
        label: 'Lien',
        initialValue: record?.link ?? '',
        colSpan: 2,
        rules: [
          {
            type: 'url',
            message: 'Ce champ doit être une URL valide.',
          },
        ],
        extra: 'Exemple pour URL valide http://www.example.com',
      },
      {
        key: 'isArchived',
        label: 'Status',
        widget: 'radio-group',
        // eslint-disable-next-line no-nested-ternary
        initialValue: (record?.isArchived === true ? 'Masquée' : 'Publiée') ?? 'Publiée',
        buttonGroup: true,
        options,
      },
    ],
  };

  const uplodMultipleFiles = (data) => {
    setMultipleFile(data.fileList);
  };

  const props = {
    beforeUpload: () => {
      return false;
    },
  };

  /* ---------------------------- SET ARABIC FIELDS --------------------------- */
  const getArabicMunicipalities = GOUVERNORAT_AR ? MUNICIPALITIES_AR[GOUVERNORAT_AR] || [] : [];
  const getArabicService = SECTOR_AR ? SERVICE_AR[SECTOR_AR] || [] : [];
  form.setFieldsValue({
    /* ------------------------ gouvernorat/municipalitie ----------------------- */
    gouvernorat_ar: REGION_AR[region.indexOf(form.getFieldValue('gouvernorat_fr'))],
    municipalitie_ar:
      getArabicMunicipalities[
      MUNICIPALITIES_FR[form.getFieldValue('gouvernorat_fr')]?.indexOf(form.getFieldValue('municipalitie_fr'))
      ],
    /* ----------------------------- sector/service ----------------------------- */
    sector_ar: SECTOR_T_AR[SECTOR.indexOf(form.getFieldValue('sector_fr'))],
    service_ar:
      getArabicService[SERVICE_FR[form.getFieldValue('sector_fr')]?.indexOf(form.getFieldValue('service_fr'))],
    /* --------------------------------- status --------------------------------- */
    status_ar: STATUS_AR[status.indexOf(form.getFieldValue('status_fr'))],
  });
  // console.log('sector_fr', form.getFieldValue('sector_ar'));
  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        transitionName=""
        maskTransitionName=""
        title={label}
        width={1000}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form
          layout="horizontal"
          onFinish={(values) => onClickSubmit(values)}
          onValuesChange={forceUpdate}
          form={form}
          encType="multipart/form-data"
        >
          <Form.Item valuePropName="gallery">
            <Dragger {...props} onChange={uplodMultipleFiles} accept="image/*" maxCount={4} multiple>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Cliquez ou faites glisser le fichier dans cette zone pour le télécharger galerie d&apos;image
              </p>
              <p className="ant-upload-hint">4 images maximum</p>
            </Dragger>
          </Form.Item>
          <Row align="middle" justify="center">
            <Avatar.Group maxCount={5}>
              {record?.gallery &&
                record?.gallery?.map((photo, i) => <Avatar key={i} src={`${API_ENDPOINT}/${photo}`} />)}
            </Avatar.Group>
          </Row>
          <FormBuilder form={form} meta={MATERIAL_REQUEST_FIELD} />

          <Row align="middle" justify="center">
            {!onlyFormItems && (
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={HRloading}>
                  Submit
                </Button>
              </Form.Item>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

MaterialHelpRequestForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
  onlyFormItems: PropTypes.bool,
};

MaterialHelpRequestForm.defaultProps = {
  isCreatedForm: false,
};

export default MaterialHelpRequestForm;

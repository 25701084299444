/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import { Form, Button, Modal, notification, Avatar, Row } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import FormBuilder from 'antd-form-builder';

// reducers
import axios from 'axios';
import { fetchAllUsers, selectAllUsers } from '../../../reducers/User.slice';

// Config
import { API_ENDPOINT } from '../../../common/config';

// helpers
import { fetchData } from '../../../common/helpers';

// constants
import { GOUVERNORAT_DATA, region } from '../../../common/constants';

// style
import '../../../App.css';

/* -------------------------------------------------------------------------- */
/*                                  User Form                                 */
/* -------------------------------------------------------------------------- */
function UserForm({ isCreatedForm, label, record }) {
  /* ---------------------------------- HOOKS --------------------------------- */
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [municipalities, setMunicipalities] = useState({});
  const forceUpdate = FormBuilder.useForceUpdate();
  const governorate = form.getFieldValue('governorate');

  const users = useSelector(selectAllUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);

  useEffect(() => {
    if (governorate && !municipalities[governorate]) {
      fetchData(GOUVERNORAT_DATA, governorate).then((arr) => {
        setMunicipalities((p) => ({ ...p, [governorate]: arr }));
      });
    }
  }, [governorate, setMunicipalities, municipalities]);

  /* ----------------------------- RENDER HELPERS ----------------------------- */
  /**
   *
   * @param {object} entry data entry from form
   */
  const onClickSubmit = (entry) => {
    const data = new FormData();

    // eslint-disable-next-line mdx/no-unused-expressions
    data.append('fullName', entry.fullName);
    data.append('email', entry.email);
    data.append('governorate', entry.governorate);
    data.append('is_active', entry.is_active);

    if (record) {
      axios
        .put(`${API_ENDPOINT}/v1/api/users/${record._id}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Utilisateur',
            description: "L'utilisateur a été mis à jour avec succès",
          });
          setShowModal(!showModal);
          dispatch(fetchAllUsers());
        })
        .catch((error) =>
          notification.error({
            message: 'Utilisateur',
            description: error.message,
          }),
        );
    } else {
      axios
        .post(`${API_ENDPOINT}/v1/api/auth/user/signup`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notification.success({
            message: 'Utilisateur',
            description: "L'utilisateur a été créé avec succès",
          });
          setShowModal(!showModal);
          dispatch(fetchAllUsers());
        })
        .catch((error) =>
          notification.error({
            message: 'Utilisateur',
            description: error.message,
          }),
        );
    }
  };

  /* -------------------------------- CONSTANTS ------------------------------- */
  const USER_ROLE = record && record.role;

  const PERSONAL_INFO = {
    columns: 2,
    fields: [
      {
        key: 'fullName',
        label: 'Nom et prénom',
        placeholder: 'Nom et prénom',
        initialValue: record?.fullName,
        disabled: !!record,
        colSpan: 2,
        rules: [
          {
            required: true,
            message: 'Nom et prénom  est obligatoire',
          },
        ],
      },
      {
        key: 'email',
        label: 'Adresse e-mail',
        placeholder: 'Adresse e-mail',
        initialValue: record?.email,
        disabled: !!record,
        colSpan: 2,
        rules: [
          {
            type: 'email',
            message: "L'entrée n'est pas valide e-mail!",
          },
          {
            required: true,
            message: 'Veuillez saisir votre e-mail !',
          },
          {
            validator: () => {
              const emailValue = form.getFieldValue('email');
              return new Promise((resolve, reject) => {
                if (users?.user?.find((b) => b.email === emailValue) && !record) {
                  reject(new Error("l'email existe déjà."));
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      {
        key: 'governorate',
        label: 'governorate',
        widget: 'select',
        options: region.map((item) => ({ label: item, value: item })),
        placeholder: 'Select governorate...',
        initialValue: record?.governorate,
        disabled: !!record,
        dynamic: true,
        widgetProps: {
          onChange: () => {
            // Clear municipality value when governorate is changed
            form.setFieldsValue({ city: undefined });
          },
        },
      },
    ],
  };

  const ACCOUNT_INFO = {
    fields: [
      {
        key: 'is_active',
        label: 'Compte activé ?',
        placeholder: 'Compte activé ?',
        widget: 'switch',
        colSpan: 1,
        initialValue: USER_ROLE?.includes('is_active') ?? false,
        extra: form.getFieldValue('is_active') === true ? 'Compte activé' : 'Compte désactivé',
      },
    ],
  };

  /* -------------------------------- RENDERING ------------------------------- */
  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(!showModal)}>
        {isCreatedForm && <PlusOutlined />}
        {label ?? <EditOutlined />}
      </Button>
      <Modal
        width={800}
        title={label}
        visible={showModal}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={() => setShowModal(!showModal)}
      >
        <Form onFinish={(values) => onClickSubmit(values)} onValuesChange={forceUpdate} form={form}>
          <Row align="middle" justify="center">
            {record?.photo && <Avatar size={120} src={`${API_ENDPOINT}/${record.photo}`} />}
          </Row>

          <FormBuilder form={form} meta={PERSONAL_INFO} />
          <FormBuilder form={form} meta={ACCOUNT_INFO} />

          <Row justify="center">
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

UserForm.propTypes = {
  record: PropTypes.object,
  label: PropTypes.string,
  isCreatedForm: PropTypes.bool,
};

UserForm.defaultProps = {
  isCreatedForm: false,
};

export default UserForm;
